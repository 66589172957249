const Options = {
  side: ['BUY', 'SELL', 'SHRT', 'COVR', 'SELL_T', 'COVR_T'],
  cfdType: ['', 'CFD', 'FFS'],
  venue: ['EMSX', 'CATS', 'CICCIMS', 'XUNTOU', 'FIX', 'ATX', 'MANUAL', 'PEMS'],
  handlingInstruction: ['LT', 'HT'],
  algo: ['INLINE', 'IS', 'TWAP', 'VWAP', 'DMA', 'ICEBERG', 'AI'],
  orderType: ['LMT', 'MKT'],
  timeInForce: ['DAY', 'GTC'],
  ruleType: ['ROUTING', 'AUTO', 'ALLOCATION', 'CROSS', 'LOANRATE', 'FOLLOWRATE'],
  requestType: ['TRD', 'IPO'],
  allocMethod: ['MANUAL'],
  loanType: ['', 'PTH', 'CONTRACT']
};

export default Options;
