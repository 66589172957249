import React, { PureComponent } from 'react';
import { Modal, Button, Upload, Select, InputNumber } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import client from '../../api/client';
import { Message } from 'semantic-ui-react';
import _ from 'lodash';
import { HotTable } from '@handsontable/react';
import hotTableUtils from 'common/ui/hotTableUtils';
import { nonOrderLocatesTableColumns } from './GridColumnMap';
import TickerUtils from 'common/utils/TickerUtils';
import Papa from 'papaparse';
import config from 'common/config';

const csvParseConfig = {
  dynamicTyping: true,
  encoding: 'GB2312',
  skipEmptyLines: true,
  worker: false,
  header: true
};

const { Option } = Select;
const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};
class NonOrderLocatesDialog extends PureComponent {
  state = {
    locates: !_.isEmpty(this.props.locates) ? this.props.locates : [],
    submitStatus: 'READY',
    gridWrapperStyle: {
      width: '100%',
      height: '300px',
      marginTop: '5px'
    },
    hotTableSettings: null,
    selectedLocate: null,
    custodians: [],
    showUpload: this.props.showUpload ? true : false,
    filterBroker: null,
    filterRate: null,
    shortRes: {},
    locateMode: config.system,
    sourceData: [],
    sourceLocate: [],
    onShoreBrokerList: ['CICC', 'CITIC'],
    brokerFundMap: {
      CICC: ['CVF', 'DCL'],
      CITIC: ['CVF', 'DCL']
    }
  };

  componentDidMount() {
    this._init();
  }

  _init = () => {
    const { limitBrokers } = this.props;
    Promise.all([client.getLocateOptions()])
      .then(([{ custodians }]) => {
        const custodianMap = _.keyBy(
          custodians.filter(
            c =>
              c.enableLocate &&
              (_.isEmpty(limitBrokers) ||
                limitBrokers.includes(c.custodianCode))
          ),
          c => c.custodianCode
        );

        _.delay(() => {
          this.setState({
            isInitialized: true,
            custodians: Object.keys(custodianMap)
          });
        }, 300);
      })
      .catch(ex => {
        console.log(ex);
      });
  };

  _createLocatesGridSettings = () => {
    const { custodians, locateMode } = this.state;
    const optionsMapping = {
      broker: custodians
    };
    const columns = nonOrderLocatesTableColumns
      .filter(r => !(locateMode === 'OFFSHORE' && r.data === 'fundCode'))
      .map(c => {
        const values = optionsMapping[c.data];
        return values
          ? {
              ...c,
              source: values
            }
          : c;
      });
    return hotTableUtils.createSettings({
      columns,
      rowHeaders: true,
      contextMenu: {
        items: {
          remove_row: {},
          add_row: {
            name: 'Add row',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._addLocates(), 100);
            }
          },
          clone: {
            name: 'Clone row',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._cloneLocates(), 100);
            }
          }
        }
      }
    });
  };

  _cloneLocates = () => {
    const { selectedLocate, row, locates } = this.state;
    if (!_.isEmpty(selectedLocate)) {
      const clonedLocates = [{ ...selectedLocate }];
      const updatedLocates = [
        ...locates.slice(0, row + 1),
        ...clonedLocates,
        ...locates.slice(row + 1)
      ];
      this.setState({
        locates: updatedLocates
      });
    }
  };

  _addLocates = () => {
    const { row, locates } = this.state;
    if (!_.isNil(row)) {
      const addLocates = [{ qty: 100 }];
      const updatedLocates = [
        ...locates.slice(0, row + 1),
        ...addLocates,
        ...locates.slice(row + 1)
      ];
      this.setState({
        locates: updatedLocates
      });
    }
  };

  _beforeRemoveRow = (row, count, rows, source) => {
    const { locates } = this.state;
    const removedOrders = locates.filter((_, i) => !rows.includes(i));

    _.delay(() => {
      this.setState(
        {
          locates: removedOrders
        },
        this._validate
      );
    }, 100);

    return false;
  };

  _onAfterSelectionEnd = row => {
    const { locates, selectedLocate } = this.state;
    const locate = locates[row];

    if (locate === selectedLocate) return;
    this.setState({
      selectedLocate: locate,
      row
    });
  };

  _beforePaste = (data, range) => {
    if (_.isEmpty(data)) return false;
    const tickers = data.map(r =>
      r[0].endsWith('Equity') ? r[0] : `${r[0]} Equity`
    );
    this._loadTickerData(tickers, []);
  };

  getLocatesData = (locateData, shortRes) => {
    const { noNeedGen, noNeedDefaultQty } = this.props;
    const { brokerFundMap } = this.state;
    const { data } = shortRes || {};
    const newLocateData = [];
    locateData.forEach((r, index) => {
      const detailData = data ? data[r.ticker] : [];
      if (this.state.locateMode === 'OFFSHORE') {
        newLocateData.push({
          ...r,
          ...this._getShortItem(detailData, r.broker, r.ticker, r.offShore, {}),
          offShore: this._getOffShore(r.offShore),
          qty: noNeedDefaultQty ? null : r.qty
        });
      }
      if (
        r.ticker.endsWith('CH Equity') &&
        this.state.locateMode === 'OFFSHORE' &&
        !noNeedGen
      ) {
        const suffix = r.ticker.startsWith('6') ? 'C1 Equity' : 'C2 Equity';
        const newTicker = r.ticker.replace('CH Equity', suffix);
        newLocateData.push({
          ...r,
          ticker: newTicker,
          ...this._getShortItem(detailData, r.broker, newTicker, r.offShore, {
            availableQty: 0,
            rate: 0
          }),
          offShore: this._getOffShore(r.offShore)
        });
      }
      if (brokerFundMap[r.broker] && this.state.locateMode === 'ONSHORE') {
        brokerFundMap[r.broker].forEach(item => {
          newLocateData.push({
            ...r,
            fundCode: item,
            offShore: this._getOffShore(r.offShore)
          });
        });
      }
    });
    return newLocateData
      ? newLocateData.filter(r => _.isNil(r.availableQty) || r.availableQty > 0)
      : newLocateData;
  };

  _getOffShore = offShore => {
    const { locateMode } = this.state;
    if (_.isUndefined(offShore)) return locateMode === 'OFFSHORE' ? 'Y' : 'N';
    return offShore === 0 ? 'N' : 'Y';
  };

  _getShortItem = (data, broker, ticker, offShore, defaultItem) => {
    if (_.isEmpty(data)) return defaultItem;
    const filterData = data.filter(
      r => r.broker === broker && r.ticker === ticker && r.offShore === offShore
    );
    return _.isEmpty(filterData)
      ? defaultItem
      : { availableQty: filterData[0].availableQty, rate: filterData[0].rate };
  };

  _loadTickerData = (tickers, locates, row) => {
    client
      .calcNonOrderLocatesContext({ tickers })
      .then(resp => {
        const { result, shortRes } = resp;
        this._buildData(result, shortRes, locates, row);
      })
      .catch(err => {
        console.log(err);
      });
  };

  _build = () => {
    const { sourceData, shortRes } = this.state;
    this._buildData(sourceData, shortRes, []);
  };

  _buildData = (result, shortRes, locates, row) => {
    const { custodians, filterBroker, filterRate } = this.state;
    const { marketList } = this.props;
    let locatesData = _.isEmpty(locates) ? [] : [...locates];
    const offShoreMode = this.state.locateMode === 'OFFSHORE' ? 1 : 0;
    const locateData = !_.isEmpty(result)
      ? result.filter(
          r =>
            (custodians.includes(r.broker) || _.isEmpty(r.broker)) &&
            (r.offShore === offShoreMode || _.isUndefined(r.offShore)) &&
            (_.isEmpty(filterBroker) || r.broker === filterBroker) &&
            (_.isNil(filterRate) || r.rate <= filterRate) &&
            (_.isNil(r.availableQty) || r.availableQty > 0)
        )
      : [];
    // if (_.isEmpty(locates)) {
    //   locatesData = locateData;
    // } else {
    const locData = this.getLocatesData(locateData, shortRes);
    locatesData = [
      ...locatesData.slice(0, row),
      ...locData,
      ...locatesData.slice(row + 1)
    ];
    locatesData = locatesData.filter(
      r =>
        _.isEmpty(marketList) ||
        marketList.includes(TickerUtils.parseExch(r.ticker))
    );
    _.delay(() => {
      this.setState(
        {
          sourceLocate: locatesData,
          locates: locatesData,
          shortRes,
          sourceData: result
        },
        this._validate
      );
    }, 100);
  };

  _filterData= () => {
    const { sourceLocate, filterBroker, filterRate } = this.state
    const locateData = !_.isEmpty(sourceLocate)
      ? sourceLocate.filter(
          r =>
            (_.isEmpty(filterBroker) || r.broker === filterBroker) &&
            (_.isNil(filterRate) || r.rate <= filterRate) 
        )
      : [];
    this.setState({
      locates: locateData
    })
  }

  _beforeCellChange = (changes, source) => {
    if (source !== 'edit') return;
    const realChanges = changes.filter(
      ([, , oldValue, newValue]) => oldValue !== newValue
    );
    if (!_.isEmpty(realChanges)) {
      return this._handleChanges(realChanges);
    }

    return true;
  };

  _handleChanges = realChanges => {
    const { locates } = this.state;
    const result = realChanges.map(r => {
      const tickers = [];
      const [row, field, oldValue, newValue] = r;
      if (oldValue !== newValue && field === 'ticker' && !_.isEmpty(newValue)) {
        const ticker = newValue.endsWith('Equity')
          ? newValue
          : `${newValue} Equity`;
        if (_.isEmpty(locates.filter(r => r.ticker === ticker))) {
          tickers.push(ticker);
          this._loadTickerData(tickers, [...locates], row);
          return false;
        }
      }
      return true;
    });
    if (result.includes(false)) return false;
    return true;
  };

  _afterChange = (changes, action) => {
    if (action !== 'edit') return;
    this._validate();
  };

  _validate = () => {
    const { locates, custodians } = this.state;
    if (_.isEmpty(locates)) return;
    const errors = [];
    locates.forEach((r, index) => {
      if (!r.qty || r.qty < 0) {
        errors.push(`[${index + 1}]${r.ticker} Qty should be > 0.`);
      }
      if (!r.ticker) {
        errors.push(`[${index + 1}]${r.ticker} Ticker cannot be null.`);
      } else if (!r.ticker.endsWith('Equity')) {
        errors.push(
          `[${index + 1}]${r.ticker} Ticker must be end with Equity.`
        );
      }
      if (_.isEmpty(r.broker)) {
        errors.push(`[${index + 1}]${r.ticker} Broker cannot be null.`);
      } else if (!custodians.includes(r.broker)) {
        errors.push(`[${index + 1}]${r.ticker} not support ${r.broker}.`);
      }
    });
    this.setState({
      errors
    });
    return _.isEmpty(errors);
  };

  _createLocatesGrid = () => {
    const { gridWrapperStyle, locates } = this.state;
    const hotTableSettings = this._createLocatesGridSettings();
    if (_.isEmpty(hotTableSettings)) return;
    return (
      <div style={gridWrapperStyle}>
        <HotTable
          {...hotTableSettings}
          data={locates}
          manualColumnResize={true}
          licenseKey="non-commercial-and-evaluation"
          beforePaste={this._beforePaste}
          afterChange={this._afterChange}
          beforeRemoveRow={this._beforeRemoveRow}
          beforeChange={this._beforeCellChange}
          afterSelectionEnd={this._onAfterSelectionEnd}
        />
      </div>
    );
  };

  _createErrorsPanel = () => {
    const { errors } = this.state;

    return (
      <div style={{ marginTop: '8px' }}>
        {!_.isEmpty(errors) && (
          <Message error list={errors} style={{ marginBottom: '3px' }} />
        )}
      </div>
    );
  };

  _onSubmit = () => {
    const { locates, filterBroker } = this.state;
    if (_.isEmpty(locates) || !this._validate()) return;
    const submitData = locates.filter(
      r => r.ticker && (_.isEmpty(filterBroker) || r.broker === filterBroker)
    );
    const promises = [this._saveNonOrderLocates(submitData)].filter(Boolean);

    if (promises.length > 0) {
      this.setState({ submitStatus: 'SUBMITTING' });

      Promise.all(promises).catch(() =>
        this.setState({ submitStatus: 'ERROR' })
      );
    } else {
      this.closeDialog();
    }
  };

  _saveNonOrderLocates = async submitData => {
    return client.saveNonOrderLocates(submitData).then(() => {
      this.closeDialog();
    });
  };

  closeDialog = () => {
    this.props.closeDialog();
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus } = this.state;
    return {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];
  };

  // _swithOffShore = () => {
  //   const { locateMode } = this.state;
  //   this.setState(
  //     {
  //       locateMode: locateMode === 'OffShore' ? 'OnShore' : 'OffShore'
  //     },
  //     this._build
  //   );
  // };

  _createOperationBar = () => {
    const { tradeFiles, showUpload } = this.state;
    return (
      <div className="orderDialogOperationBar">
        <div className="rightOperations">
          {showUpload && (
            <Upload fileList={tradeFiles} beforeUpload={this._parseTradeFile}>
              <Button>
                <UploadOutlined />
                Click to Upload
              </Button>
            </Upload>
          )}
          {this._createFilter()}
          {/* {!showUpload && (
            <Switch
              checked={locateMode === 'OffShore'}
              checkedChildren="OffShore"
              unCheckedChildren="OnShore"
              onChange={this._swithOffShore}
            ></Switch>
          )} */}
        </div>
      </div>
    );
  };

  _parseLocateFile = data => {
    if (_.isEmpty(data)) return [];
    return data.map(row => {
      return {
        ticker: row['Ticker'] + '',
        qty: row['Qty'] ? Math.abs(row['Qty']) : row['Qty'],
        broker: (row['Broker'] || '').toUpperCase()
      };
    });
  };

  _onParseLocates = data => {
    _.delay(() => {
      this.setState(
        {
          locates: data
        },
        this._validate
      );
    }, 100);
  };

  _parseTradeFile = file => {
    this.setState({
      tradeFiles: [file],
      isLoading: true,
      trades: []
    });

    Papa.parse(file, {
      ...csvParseConfig,
      complete: result => {
        const data = _.get(result, 'data', []);
        const locates = _.isEmpty(data) ? [] : this._parseLocateFile(data);
        this._onParseLocates(locates);
      }
    });

    return false;
  };

  _onFilterBrokerChange = value => {
    this.setState(
      {
        filterBroker: value
      },
      this._filterData
    );
  };

  _onFilterRateChange = value => {
    this.setState(
      {
        filterRate: value
      },
      this._filterData
    );
  };

  _createFilter = () => {
    const { showUpload, filterBroker, filterRate } = this.state;
    const options = [''];
    options.push(...this.state.custodians);
    return (
      !showUpload && (
        <div style={{ textAlign: 'right' }}>
          <InputNumber
            min={0}
            max={100}
            placeholder="Input Max Rate"
            formatter={value => `${value}%`}
            parser={value => value.replace('%', '')}
            style={{ marginRight: '5px', width: '150px' }}
            value={filterRate}
            size="small"
            onChange={value => {
              this._onFilterRateChange(value);
            }}
          />
          <Select
            style={{ marginRight: '5px', width: '150px' }}
            value={filterBroker}
            size="small"
            onChange={value => {
              this._onFilterBrokerChange(value);
            }}
          >
            {_createUIOptions(options)}
          </Select>
        </div>
      )
    );
  };

  render() {
    return (
      <Modal
        width={1400}
        maskClosable={false}
        title="Non Order Locates"
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          this._createSubmitBtn(this._onSubmit),
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        {this._createOperationBar()}
        {/* {this._createFilter()} */}
        {this._createLocatesGrid()}
        {this._createErrorsPanel()}
      </Modal>
    );
  }
}

export default NonOrderLocatesDialog;
