import config from 'common/config';
import apiClient from '../../../common/api/apiClient';

const loadSettings = name => {
  return apiClient.get(`${config.api.PXY_URL}/settings/getSettings`);
};

const saveUserSettings = data => {
  return apiClient.post(`${config.api.PXY_URL}/settings/saveUserSettings`, data);
};

const queryUserCustomSettings = name => {
  return apiClient.get(`${config.api.PXY_URL}/settings/queryUserCustomSettings`);
};


const client = {
  loadSettings,
  saveUserSettings,
  queryUserCustomSettings
};

export default client;
