import React from 'react';
import {
  twoDPFormatter,
  threeDPFormatter,
  fxRateFormatter,
  moneyFormatter,
  percentFormatter,
  nullFormatter,
  notANumberFormatter,
  dateFormatter,
  dateTimeFormatter,
  dateFormatterWithNotGroup
} from 'common/utils/valueFormatters';
import {
  upperCaseRenderer,
  lsfRenderer
} from 'common/ui/renderers/hotTable/renderers';
import {
  percentStyle,
  numberStyle,
  sideStyle
} from 'common/utils/styleChooser';
import EntityMap from 'entities/EntityMap';
import numeral from 'numeral';
import { statusStyle, tagStyle } from 'common/utils/styleChooser';
import client from 'features/order/api/client';
import portfolioClient from 'features/portfolio/api/client';
import researchClient from 'features/research/api/client';

export const gptNewsColumns = [
  {
    field: 'Summary',
    headerName: 'Summary',
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    cellClassRules: {
      'cell-text-read': params => params.data && params.data.isRead,
      keyword: params => !(params.data && params.data.isRead)
    },
    width: 1060
  },
  {
    field: 'Sentiment',
    headerName: 'Sentiment',
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    width: 80,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        利多: '#16ab39',
        利空: 'red',
        NEGATIVE: 'red',
        POSITIVE: '#16ab39'
      }
    }
  },
  {
    field: 'DateTime',
    headerName: 'Date',
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    width: 140
  },
  {
    field: 'URL',
    headerName: 'Detail',
    cellClass: 'non-number',
    cellRenderer: 'hyperlinkCompent',
    cellRendererParams: {
      hyperlinkName: 'Detail',
      onClickCallback: data => {
        portfolioClient.addNewsReadRecord([data]);
      },
      valueColorFunc: d => (d.isRead ? '#D3D3D3' : '#FF9E28'),
      callbackFields: ['Id', 'Ticker']
    },
    width: 80
  }
];

export const gptConferenceColumns = [
  {
    field: 'title',
    headerName: 'Title',
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    cellClassRules: {
      keyword: params =>
        !(params.data && params.data.oldUrl) && !params.data.isRead,
      'cell-text-read': params =>
        !(params.data && params.data.oldUrl) && params.data.isRead,
      'cell-left-render': params =>
        params.data && params.data.oldUrl && params.data.isRead,
      'cell-left-render keyword': params =>
        params.data && params.data.oldUrl && !params.data.isRead
    },
    width: 500
  },
  {
    field: 'summary',
    headerName: 'Summary',
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    width: 700,
    cellClassRules: {
      'cell-text-read': params => params.data && params.data.isRead,
      keyword: params => !(params.data && params.data.isRead)
    }
  },
  // {
  //   field: 'reporter',
  //   headerName: 'Reporter',
  //   cellClass: 'non-number',
  //   valueFormatter: nullFormatter,
  //   width: 170
  // },
  {
    field: 'dt',
    headerName: 'Date',
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    width: 90
  },
  {
    field: 'uri',
    headerName: 'Detail',
    cellClass: 'non-number',
    cellRenderer: 'hyperlinkCompent',
    cellRendererParams: {
      hyperlinkName: 'Detail',
      valueColorFunc: d => (d.isRead ? '#D3D3D3' : '#FF9E28'),
      onClickCallback: data => {
        portfolioClient.addNewsReadRecord([
          { Id: data.id, Ticker: data.ticker, type: 'CONFERENCE' }
        ]);
      },
      callbackFields: ['id', 'ticker']
    },
    width: 70
  }
];

const stockPitchColumns = [
  {
    field: 'type',
    headerName: 'Type',
    cellClass: 'keyword',
    enableRowGroup: 'true',
    width: 100
  },
  {
    field: 'tickerLong',
    headerName: 'Ticker(L)',
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    width: 200
  },
  {
    field: 'tickerShort',
    headerName: 'Ticker(s)',
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    width: 200
  },
  {
    field: 'suggestedPosition',
    headerName: 'Suggested Size',
    cellClass: 'number',
    valueFormatter: params => {
      if (params.value) {
        return `${params.value}%`;
      }
    },
    width: 140
  },
  {
    field: 'updateTime',
    headerName: 'Update Time',
    cellClass: 'non-number',
    valueFormatter: dateTimeFormatter,
    width: 150
  },
  {
    field: 'id',
    headerName: 'Detail',
    cellClass: 'non-number',
    cellRenderer: 'hyperlinkCompent',
    cellRendererParams: {
      hyperlinkName: 'Detail',
      callbackFields: ['id'],
      valueColorFunc: () => '',
      useDetailCompent: true,
      detailCompentName: 'stockPitchDialog',
      dataKey: 'pushVotesModal',
      closeModalFuncName: 'closePushVotesModal',
      getDetailDataFunc: async data => {
        try {
          const result = await researchClient.queryPushVotesByIdAndMarkRead({
            id: data.id
          });
          return result ? result.data : null;
        } catch (error) {
          console.log(error);
          return null;
        }
      }
    },
    width: 60
  }
];

export const pmLevelStockPitchColumns = [
  {
    field: 'englishName',
    headerName: 'Author',
    cellClass: 'keyword',
    enableRowGroup: 'true',
    width: 100,
    cellClassRules: {
      '': params => !(params.data && params.data.difs),
      'cell-left-render': params => params.data && params.data.difs
    }
  },
  {
    field: 'type',
    headerName: 'Type',
    cellClass: 'keyword',
    enableRowGroup: 'true',
    width: 100
  },
  {
    field: 'tickerLong',
    headerName: 'Ticker(L)',
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    width: 130
  },
  {
    field: 'tickerNameLong',
    headerName: 'Security(L)',
    cellClass: 'non-number',
    width: 210
  },
  {
    field: 'tickerShort',
    headerName: 'Ticker(S)',
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    width: 130
  },
  {
    field: 'tickerNameShort',
    headerName: 'Security(S)',
    cellClass: 'non-number',
    width: 210
  },
  {
    field: 'confidenceLevel',
    headerName: 'CL',
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    width: 40
  },
  {
    field: 'expectedReturn',
    headerName: 'ExpectedReturn',
    cellClass: 'non-number',
    valueFormatter: params => {
      if (params.value === undefined || params.value === null) return 'N.A.';
      return `${params.value}%`;
    },
    width: 110
  },
  {
    field: 'lossLimit',
    headerName: 'Loss limit',
    valueFormatter: params => {
      if (params.value === undefined || params.value === null) return 'N.A.';
      return `${params.value}%`;
    },
    cellClass: 'number',
    width: 80
  },
  {
    field: 'expectedDuration',
    headerName: 'Expected Duration',
    cellClass: 'non-number',
    width: 110
  },
  {
    field: 'updateTime',
    headerName: 'Update Time',
    cellClass: 'non-number',
    valueFormatter: dateTimeFormatter,
    width: 150
  },
  {
    field: 'id',
    headerName: 'Detail',
    cellClass: 'non-number',
    cellRenderer: 'hyperlinkCompent',
    cellRendererParams: {
      hyperlinkName: 'Detail',
      callbackFields: ['id'],
      valueColorFunc: () => '',
      useDetailCompent: true,
      detailCompentName: 'stockPitchDialog',
      dataKey: 'pushVotesModal',
      closeModalFuncName: 'closePushVotesModal',
      getDetailDataFunc: async data => {
        try {
          const result = await researchClient.queryPushVotesByIdAndMarkRead({
            id: data.id
          });
          return result ? result.data : null;
        } catch (error) {
          console.log(error);
          return null;
        }
      }
    },
    width: 60
  }
];

export const companyScoreColumns = [
  {
    field: 'createBy',
    headerName: 'Create By',
    cellClass: 'non-number',
    width: 180,
    cellClassRules: {
      '': params => !(params.data && params.data.difc),
      'cell-left-render': params => params.data && params.data.difc
    }
  },
  {
    headerName: 'Long-term Factors',
    children: [
      {
        field: 'score',
        headerName: 'Score',
        cellClass: 'non-number',
        cellRenderer: 'ratingBarComponent',
        cellRendererParams: {
          className: 'blueStar'
        },
        width: 180
      },
      {
        field: 'longInvestmentConclusion',
        headerName: 'Investment Direction',
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        width: 210,
        cellRendererParams: {
          colorMap: {
            Long: '#16ab39',
            Short: 'red',
            Ignore: 'yellow',
            'No action': 'yellow'
          }
        }
      }
    ]
  },
  {
    headerName: 'Short-term Factors',
    children: [
      {
        field: 'shortInvestmentConclusion',
        headerName: 'Investment Conclusion',
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        width: 210,
        cellRendererParams: {
          colorMap: {
            Long: '#16ab39',
            Short: 'red',
            Ignore: 'yellow',
            'No action': 'yellow'
          }
        }
      },
      {
        field: 'marketView',
        headerName: 'Market Sentiments Score',
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        width: 200,
        cellRendererParams: {
          colorMap: {
            Long: '#16ab39',
            Short: 'red',
            Ignore: 'yellow',
            'No action': 'yellow'
          }
        }
      },
      {
        field: 'moodScore',
        headerName: 'Chip&Flow Score',
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        width: 210,
        cellRendererParams: {
          colorMap: {
            Long: '#16ab39',
            Short: 'red',
            Ignore: 'yellow',
            'No action': 'yellow'
          }
        }
      }
    ]
  },
  {
    field: 'updateTime',
    headerName: 'Update Time',
    cellClass: 'non-number',
    valueFormatter: dateTimeFormatter,
    width: 180
  },
  {
    field: 'id',
    headerName: 'Detail',
    cellClass: 'non-number',
    cellRenderer: 'hyperlinkCompent',
    cellRendererParams: {
      hyperlinkName: 'Detail',
      callbackFields: ['id'],
      valueColorFunc: () => '',
      useDetailCompent: true,
      detailCompentName: 'companyScoreDetail',
      dataKey: 'modalData',
      closeModalFuncName: 'closeDrawer',
      getDetailDataFunc: async data => {
        return data;
      }
    },
    width: 60
  }
];

const positionGridColumns = [
  {
    field: 'fundCode',
    headerName: 'Fund',
    enableRowGroup: true,
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'bookCode',
    headerName: 'Book',
    enableRowGroup: true,
    cellClass: 'non-number',
    enablePivot: true,
    hide: true
  },
  {
    field: 'strategy',
    headerName: 'Strategy',
    enableRowGroup: true,
    cellClass: 'non-number',
    enablePivot: true,
    valueFormatter: nullFormatter,
    hide: true
  },
  {
    field: 'custodianCode',
    headerName: 'Custodian',
    enableRowGroup: true,
    cellClass: 'keyword',
    enablePivot: true,
    valueFormatter: nullFormatter,
    hide: true
  },
  {
    field: 'securityCode',
    headerName: 'Security',
    enablePivot: true,
    enableRowGroup: true,
    cellClass: 'non-number'
  },
  {
    field: 'issuerCode',
    headerName: 'Issuer',
    enableRowGroup: true,
    enablePivot: true,
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'moneyness',
    headerName: 'Moneyness',
    enablePivot: true,
    enableRowGroup: true,
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'maturityDate',
    headerName: 'Maturity',
    enableRowGroup: true,
    enablePivot: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    hide: true
  },
  {
    field: 'exchangeCountryCode',
    headerName: 'Exch Country',
    enableRowGroup: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    enablePivot: true,
    hide: true
  },
  {
    field: 'exchangeContinentCode',
    headerName: 'Continent',
    enableRowGroup: true,
    enablePivot: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    hide: true
  },
  {
    field: 'countryCode',
    headerName: 'Country',
    enableRowGroup: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    enablePivot: true,
    hide: true
  },
  {
    field: 'bondRiskType',
    headerName: 'Bond Risk Type',
    enableRowGroup: true,
    enablePivot: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    hide: true
  },
  {
    field: 'sectorCode',
    headerName: 'Sector',
    enableRowGroup: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    enablePivot: true,
    hide: true
  },
  {
    field: 'reportDate',
    headerName: 'RPT Date',
    enableRowGroup: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    enablePivot: true,
    hide: true
  },
  {
    field: 'instrumentClass',
    headerName: 'Inst Class',
    enableRowGroup: true,
    cellClass: 'non-number',
    enablePivot: true,
    hide: true
  },
  {
    field: 'positionTypeCode',
    headerName: 'Pos Type',
    enableRowGroup: true,
    enablePivot: true,
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'contractId',
    headerName: 'Contract Id',
    enableRowGroup: true,
    enablePivot: true,
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    enableRowGroup: true,
    enablePivot: true,
    cellClass: 'keyword',
    pinned: 'left',
    lockPinned: true
  },
  {
    field: 'undlTicker',
    headerName: 'Undl Ticker',
    enableRowGroup: true,
    enablePivot: true,
    cellClass: 'keyword',
    hide: true
  },
  {
    field: 'currencyCode',
    headerName: 'CCY',
    enablePivot: true,
    enableRowGroup: true,
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'fxExposure1Ccy',
    headerName: 'Fx EXP 1 CCY',
    enableRowGroup: true,
    enablePivot: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    hide: true
  },
  {
    field: 'fxExposure1',
    headerName: 'Fx EXP 1',
    valueFormatter: moneyFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: numberStyle(),
    hide: true
  },
  {
    field: 'fxExposure2Ccy',
    headerName: 'Fx EXP 2 CCY',
    enablePivot: true,
    enableRowGroup: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    hide: true
  },
  {
    field: 'fxExposure2',
    headerName: 'Fx EXP 2',
    valueFormatter: moneyFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: numberStyle(),
    hide: true
  },
  {
    field: 'avgCostLocal',
    headerName: 'Cost',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: threeDPFormatter
  },
  {
    field: 'borrowRate',
    headerName: 'Borrow Rate%',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: twoDPFormatter
  },
  {
    field: 'endPriceLocal',
    headerName: 'Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: threeDPFormatter
  },
  {
    field: 'undlEndPriceLocal',
    headerName: 'Undl Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: notANumberFormatter,
    hide: true
  },
  {
    field: 'endPricingSource',
    headerName: 'Px Source',
    enablePivot: true,
    enableRowGroup: true,
    cellClass: 'keyword',
    hide: true
  },
  {
    field: 'priceLocalStart',
    headerName: 'Start Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: threeDPFormatter
  },
  {
    field: 'priceBookStart',
    headerName: 'Start Book Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: threeDPFormatter,
    hide: true
  },
  {
    field: 'avgCostBook',
    headerName: 'Book Cost',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: threeDPFormatter,
    hide: true
  },
  {
    field: 'endPriceBook',
    headerName: 'Book Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: threeDPFormatter,
    hide: true
  },
  {
    field: 'priceChangePct',
    headerName: 'Book Price %',
    valueFormatter: percentFormatter,
    cellClassRules: percentStyle()
  },
  {
    field: 'localPxChgPct',
    headerName: 'Price %',
    valueFormatter: percentFormatter,
    cellClassRules: percentStyle()
  },
  {
    field: 'bookFxRateStart',
    headerName: 'Start Book Fx',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: fxRateFormatter,
    hide: true
  },
  {
    field: 'endBookFxRate',
    headerName: 'Book Fx',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: fxRateFormatter,
    hide: true
  },
  {
    field: 'quantityEnd',
    headerName: 'Qty',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  },
  {
    field: 'liquidity',
    headerName: 'Liquidity',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: twoDPFormatter,
    hide: true
  },
  {
    field: 'liquidityCategory',
    headerName: 'Liquidity Cat.',
    enableRowGroup: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    enablePivot: true,
    hide: true
  },
  {
    field: 'marketCap',
    headerName: 'Market Cap Usd',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: moneyFormatter,
    hide: true
  },
  {
    field: 'marketCapCategory',
    headerName: 'Market Cap Cat.',
    enableRowGroup: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    enablePivot: true,
    hide: true
  },
  {
    field: 'beta',
    headerName: 'Beta',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: notANumberFormatter,
    hide: true
  },
  {
    field: 'delta',
    headerName: 'Delta',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: notANumberFormatter,
    hide: true
  },
  // {
  //   field: 'fundHldPctPred',
  //   headerName: 'Hld% Pred',
  //   // cellRenderer: 'agAnimateShowChangeCellRenderer',
  //   valueFormatter: percentFormatter,
  //   // cellClassRules: percentStyle(),
  //   cellRenderer: 'percentBarComponent',
  //   cellRendererParams: {
  //     factor: 7
  //   },
  //   cellClass: 'number',
  //   hide: true
  // },
  {
    field: 'tradeDelta',
    headerName: 'Trade Delta',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: notANumberFormatter,
    hide: true
  },
  {
    field: 'multiplier',
    headerName: 'Multiplier',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    hide: true
  },
  {
    field: 'roundLotSize',
    headerName: 'Lot Size',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    hide: true
  },
  {
    field: 'parityStart',
    headerName: 'Parity Start',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: notANumberFormatter,
    hide: true
  },
  {
    field: 'parity',
    headerName: 'Parity',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: notANumberFormatter,
    hide: true
  },
  {
    field: 'nukePriceLocal',
    headerName: 'Nuke Px Local',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: notANumberFormatter,
    hide: true
  },
  {
    field: 'avgVolume10Day',
    headerName: 'AVG VOL 10D',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    hide: true
  },
  {
    field: 'avgVolume20Day',
    headerName: 'AVG VOL 20D',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    hide: true
  },
  {
    field: 'avgVolume30Day',
    headerName: 'AVG VOL 30D',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    hide: true
  },
  {
    field: 'targetQuantityEnd',
    headerName: 'TGT Qty',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle(),
    hide: true
  },
  {
    field: 'targetQuantityEndForClose',
    headerName: 'TGT Qty Close',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle(),
    hide: true
  },
  {
    field: 'quantityStart',
    headerName: 'Qty Start',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  },
  {
    field: 'quantityDirection',
    headerName: 'LSF',
    enableRowGroup: true,
    cellClassRules: {
      long: params => params.value === 'LONG',
      short: params => params.value === 'SHORT',
      flat: params => params.value !== 'LONG' && params.value !== 'SHORT'
    },
    enablePivot: true
  },
  {
    field: 'mktValBook',
    headerName: 'NET VAL',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: numberStyle(),
    hide: true
  },
  {
    field: 'notnlMktValBook',
    headerName: 'MV',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: numberStyle(),
    hide: true
  },
  {
    field: 'notnlMktValBookPct',
    headerName: 'MV %',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 7
    },
    aggFunc: 'sum',
    enableValue: true,
    cellClass: 'number'
  },
  {
    field: 'deltaNotnlMktValBook',
    headerName: 'DeltaMV',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: numberStyle(),
    hide: true
  },
  {
    field: 'deltaNotnlMktValBookPct',
    headerName: 'DeltaMV %',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 7
    },
    aggFunc: 'sum',
    enableValue: true,
    cellClass: 'number'
  },
  {
    field: 'absNotnlMktValBookPct',
    headerName: 'GMV %',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 7
    },
    aggFunc: 'sum',
    enableValue: true,
    cellClass: 'number'
  },
  {
    field: 'targetNotnlMktValBookPct',
    headerName: 'TGT MV %',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 7
    },
    aggFunc: 'sum',
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'betaNotnlMktValBook',
    headerName: 'Beta MV',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: numberStyle(),
    hide: true
  },
  {
    field: 'betaNotnlMktValBookPct',
    headerName: 'Beta MV %',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 7
    },
    aggFunc: 'sum',
    enableValue: true,
    cellClass: 'number'
  },
  {
    field: 'targetBetaNotnlMktValBookPct',
    headerName: 'TGT Beta MV %',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 7
    },
    aggFunc: 'sum',
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'dtdPricePnl',
    headerName: 'DTD Price PNL',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: numberStyle(),
    hide: true
  },
  {
    field: 'dtdFxPnl',
    headerName: 'DTD Fx PNL',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: numberStyle(),
    hide: true
  },
  {
    field: 'dtdTradingPnl',
    headerName: 'DTD Trading PNL',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: numberStyle(),
    hide: true
  },
  {
    field: 'dtdCashIncomePnl',
    headerName: 'DTD Cash PNL',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: numberStyle(),
    hide: true
  },
  {
    field: 'dtdAccrualsPnl',
    headerName: 'DTD Accruals PNL',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: numberStyle(),
    hide: true
  },
  {
    field: 'dtdPnl',
    headerName: 'DTD PNL',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: numberStyle()
  },
  {
    field: 'dtdPnlPct',
    headerName: 'DTD PNL%',
    valueFormatter: percentFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: percentStyle()
  },
  {
    field: 'mtdPnl',
    headerName: 'MTD PNL',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: numberStyle(),
    hide: true
  },
  {
    field: 'mtdPnlPct',
    headerName: 'MTD PNL%',
    valueFormatter: percentFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: percentStyle()
  },
  {
    field: 'ytdPnl',
    headerName: 'YTD PNL',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: numberStyle(),
    hide: true
  },
  {
    field: 'ytdPnlPct',
    headerName: 'YTD PNL%',
    valueFormatter: percentFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: percentStyle()
  },
  {
    field: 'priceReturn',
    headerName: 'Price Return',
    valueFormatter: percentFormatter,
    cellClassRules: percentStyle(),
    hide: true
  },
  {
    field: 'lastUpdatedOn',
    headerName: 'Last Updated',
    enablePivot: true,
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'tag1',
    headerName: 'Tag1',
    enablePivot: true,
    cellClass: 'non-number',
    enableRowGroup: true,
    cellClassRules: tagStyle(),
    valueFormatter: nullFormatter
  },
  {
    field: 'tag2',
    headerName: 'Tag2',
    enablePivot: true,
    cellClass: 'non-number',
    enableRowGroup: true,
    cellClassRules: tagStyle(),
    valueFormatter: nullFormatter
  },
  {
    field: 'tag3',
    headerName: 'Tag3',
    enablePivot: true,
    cellClass: 'non-number',
    enableRowGroup: true,
    cellClassRules: tagStyle(),
    valueFormatter: nullFormatter
  },
  {
    field: 'tagSector',
    headerName: 'Tag Sector',
    enablePivot: true,
    cellClass: 'non-number',
    enableRowGroup: true,
    cellClassRules: tagStyle(),
    valueFormatter: nullFormatter
  },
  {
    field: 'tagSubSector',
    enablePivot: true,
    headerName: 'Tag SubSector',
    cellClass: 'non-number',
    enableRowGroup: true,
    cellClassRules: tagStyle(),
    valueFormatter: nullFormatter
  },
  {
    field: 'suggestedSize',
    headerName: 'Suggested Size%',
    cellClass: 'number',
    valueFormatter: params => {
      if (params.value) {
        return `${numeral(params.value).format('0.00')}%`;
      }
      return '';
    },
    cellClassRules: percentStyle(),
    cellRenderer: 'tableTooltipComponent',
    cellRendererParams: {
      tooltipTitle: <b style={{ fontSize: '16px' }}>Stock Pitch</b>,
      tooltipField: 'stockPitches',
      tooltipColumnDefs: stockPitchColumns,
      tooltipWidth: '850px',
      tooltipHeight: '390px'
    }
  },
  {
    field: 'stockNewsNum',
    headerName: 'NEWS',
    valueFormatter: nullFormatter,
    cellRenderer: 'portfolioTabTooltipComponent',
    cellRendererParams: {
      tooltipTitle: <b style={{ fontSize: '16px' }}>Intelligence</b>,
      tooltipField: 'rsrchInfo',
      tooltipWidth: '1360px',
      tooltipHeight: '450px',
      tooltiGridHeight: '420px'
    },
    cellClass: 'keyword'
  },
  {
    field: 'companyScoreNums',
    headerName: 'Research Infos',
    valueFormatter: nullFormatter,
    cellRenderer: 'researchInfoToolTipComponent',
    cellRendererParams: {
      tooltipTitle: <b style={{ fontSize: '16px' }}>Research Infos</b>,
      tooltipField: 'rsrchInfo',
      tooltipWidth: '1450px',
      tooltipHeight: '700px',
      tooltiGridHeight: '324px'
    },
    cellClass: 'keyword'
  },
  {
    field: 'settleType',
    headerName: 'Settle Type',
    enableRowGroup: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    enablePivot: true
  },
  {
    field: 'momentum',
    headerName: 'Momentum',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: notANumberFormatter,
    hide: true
  },
  {
    field: 'momentumNotnlMktValBook',
    headerName: 'Momentum MV',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: numberStyle(),
    hide: true
  },
  {
    field: 'momentumNotnlMktValBookPct',
    headerName: 'Momentum MV %',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 7
    },
    aggFunc: 'sum',
    enableValue: true,
    cellClass: 'number'
  },
];

const riskBarColumns = [
  // {
  //   field: 'bookCode',
  //   label: 'Book',
  //   labelFn: r => `${(r && r.fundCode) || 'NA'}`,
  //   valueFn: r => `${(r && r.bookCode) || 'NA'}`
  // },
  {
    field: 'numOfNames',
    label: 'Names',
    valueFn: r => (r.numOfNames ? r.numOfNames : 0)
  },
  {
    field: 'nav',
    labelFn: r => (r.aggregateLevel === 'FUND' ? 'NAV' : 'GAV'),
    valueFn: r => numeral(r.nav).format('0.00a')
  },
  {
    field: 'dtdRet',
    label: 'DTD',
    valueFn: r => percentFormatter({ value: r.dtdRet })
  },
  {
    field: 'dtdFxRet',
    label: 'DTD FX',
    valueFn: r => percentFormatter({ value: r.dtdFxRet })
  },
  {
    field: 'mtdRet',
    label: 'MTD',
    valueFn: r => percentFormatter({ value: r.mtdRet })
  },
  {
    field: 'ytdRet',
    label: 'YTD',
    valueFn: r => percentFormatter({ value: r.ytdRet })
  },

  {
    field: 'maxDD',
    label: 'MaxDD',
    valueFn: r => percentFormatter({ value: r.maxDD })
  },
  {
    field: 'currDD',
    label: 'CurrDD',
    valueFn: r => percentFormatter({ value: r.currDD })
  },
  {
    field: 'curr1YDD',
    label: 'Curr1YDD',
    valueFn: r => percentFormatter({ value: r.curr1YDD })
  },
  {
    field: 'relYtdRet',
    label: 'R-YTD',
    valueFn: r => percentFormatter({ value: r.relYtdRet })
  },
  {
    field: 'relMaxDD',
    label: 'R-MaxDD',
    valueFn: r => percentFormatter({ value: r.relMaxDD })
  },
  {
    field: 'relCurrDD',
    label: 'R-CurrDD',
    valueFn: r => percentFormatter({ value: r.relCurrDD })
  },
  {
    field: 'gt2Days',
    label: '>2Days',
    valueFn: r => percentFormatter({ value: r.gt2Days })
  },

  // {
  //   field: 'longEquityBetaPct',
  //   label: 'L-Eqtyβ',
  //   valueFn: r => percentFormatter({ value: r.longEquityBetaPct })
  // },
  // {
  //   field: 'shortEquityBetaPct',
  //   label: 'S-Eqtyβ',
  //   valueFn: r => percentFormatter({ value: r.shortEquityBetaPct })
  // },
  {
    field: 'netEquityBetaPct',
    label: 'N-Eqtyβ',
    valueFn: r => percentFormatter({ value: r.netEquityBetaPct })
  },
  {
    field: 'netEquityPct',
    label: 'N-Eqty',
    valueFn: r => percentFormatter({ value: r.netEquityPct })
  },
  {
    field: 'grossEquityPct',
    label: 'G-Eqty',
    valueFn: r => percentFormatter({ value: r.grossEquityPct })
  },
  {
    field: 'grossPct',
    label: 'Gross',
    valueFn: r => percentFormatter({ value: r.grossPct })
  },
  {
    field: 'netGrossRatio',
    label: 'N-Eqtyβ/G',
    valueFn: r => percentFormatter({ value: r.netGrossRatio })
  },
  // {
  //   field: 'alpha',
  //   label: 'Alpha',
  //   valueFn: r => percentFormatter({ value: r.alpha })
  // },
  // {
  //   field: 'beta',
  //   label: 'Beta',
  //   valueFn: r => twoDPFormatter({ value: r.beta })
  // },
  {
    field: 'sharpe',
    label: 'Sharpe',
    valueFn: r => twoDPFormatter({ value: r.sharpe })
  },
  {
    field: 'annVol',
    label: 'AnnVol',
    valueFn: r => percentFormatter({ value: r.annVol })
  },
  {
    field: 'pxTrackingAlerts',
    label: 'Px Alerts',
    valueFn: r => (r.pxTrackingAlerts ? r.pxTrackingAlerts.length : 0)
  },

  // {
  //   field: 'targetLongEquityBetaPct',
  //   label: 'TGT Long',
  //   valueFn: r => percentFormatter({ value: r.targetLongEquityBetaPct })
  // },
  // {
  //   field: 'targetShortEquityBetaPct',
  //   label: 'TGT Short',
  //   valueFn: r => percentFormatter({ value: r.targetShortEquityBetaPct })
  // },
  // {
  //   field: 'targetNetEquityBetaPct',
  //   label: 'TGT Net',
  //   valueFn: r => percentFormatter({ value: r.targetNetEquityBetaPct })
  // },
  // {
  //   field: 'targetGrossEquityPct',
  //   label: 'TGT Gross',
  //   valueFn: r => percentFormatter({ value: r.targetGrossEquityPct })
  // },
  // {
  //   field: 'nonEquityPct',
  //   label: 'Non-EQ',
  //   valueFn: r => percentFormatter({value: r.nonEquityPct})
  // },
  // {
  //   field: 'riskControlLimit',
  //   label: 'Risk LMT',
  //   valueFn: r => (!!r.riskControlLimit ? 'Above' : 'Under')
  // },
  {
    field: 'riskCheckStatus',
    label: 'Risk LMT',
    valueFn: r => r.riskCheckStatus,
    valueClassRules: statusStyle()
  }
];

export const cbDeltaGridColumns = [
  {
    data: 'ticker',
    headerName: 'Ticker',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'securityCode',
    headerName: 'Security',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'instrumentClass',
    headerName: 'Inst Class',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'notnlMktValBookPct',
    headerName: 'MV %',
    readOnly: true,
    allowEmpty: false,
    type: 'numeric',
    numericFormat: {
      pattern: '0.00 %'
    }
  },
  {
    data: 'delta',
    headerName: 'Delta',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'tradeDelta',
    headerName: 'Trade Delta',
    readOnly: false,
    allowEmpty: false
  }
];

export const tagColumns = [
  {
    data: 'fundCode',
    headerName: 'Fund',
    cellClass: 'non-number',
    readOnly: true
  },
  {
    data: 'bookCode',
    headerName: 'Book',
    readOnly: true,
    cellClass: 'non-number'
  },
  {
    data: 'strategy',
    headerName: 'Strategy',
    cellClass: 'non-number',
    readOnly: true,
    valueFormatter: nullFormatter
  },
  {
    data: 'securityCode',
    headerName: 'Security',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'quantityDirection',
    headerName: 'LSF',
    renderer: lsfRenderer,
    readOnly: true
  },
  {
    data: 'quantityEnd',
    headerName: 'Qty',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    type: 'numeric',
    readOnly: true
  },
  {
    data: 'tag1',
    headerName: 'Tag1',
    readOnly: false,
    allowEmpty: true,
    renderer: upperCaseRenderer
  },
  {
    data: 'tag2',
    headerName: 'Tag2',
    readOnly: false,
    allowEmpty: true,
    renderer: upperCaseRenderer
  },
  {
    data: 'tag3',
    headerName: 'Tag3',
    readOnly: false,
    allowEmpty: true,
    renderer: upperCaseRenderer
  },
  {
    data: 'tag4',
    headerName: 'Tag Sector',
    readOnly: false,
    allowEmpty: true,
    renderer: upperCaseRenderer
  },
  {
    data: 'tag5',
    headerName: 'Tag SubSector',
    readOnly: false,
    allowEmpty: true,
    renderer: upperCaseRenderer
  }
];

export const pairPosColumns = [
  {
    data: 'strategy',
    headerName: 'Strategy',
    cellClass: 'non-number',
    readOnly: true,
    valueFormatter: nullFormatter
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    type: 'autocomplete',
    readOnly: true
  },
  {
    data: 'securityCode',
    headerName: 'Security',
    readOnly: true
  },
  {
    data: 'quantityDirection',
    headerName: 'LSF',
    readOnly: true
  },
  {
    data: 'quantityEnd',
    headerName: 'Qty',
    readOnly: true,
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    }
  },
  {
    data: 'notnlMktValBook',
    headerName: 'MV(BaseCcy)',
    readOnly: true,
    type: 'numeric',
    numericFormat: {
      pattern: '0,0.0'
    }
  }
];

export const pairPriceTraceingColumns = [
  {
    data: 'strategy',
    headerName: 'Strategy',
    cellClass: 'non-number',
    readOnly: true,
    valueFormatter: nullFormatter
  },
  {
    data: 'longTicker',
    headerName: 'longTicker',
    type: 'autocomplete',
    source(query, process) {
      if (query.length <= 3) return [];
      client.searchTickers(query).then(response => process(response.tickers));
    },
    strict: true
  },
  {
    data: 'longSecurityCode',
    headerName: 'LongSecurity',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'shortTicker',
    headerName: 'ShortTicker',
    type: 'autocomplete',
    source(query, process) {
      if (query.length <= 3) return [];
      client.searchTickers(query).then(response => process(response.tickers));
    },
    strict: true
  },
  {
    data: 'shortSecurityCode',
    headerName: 'ShortSecurity',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'longShortRatio',
    headerName: 'Long/Short Ratio',
    readOnly: false,
    allowEmpty: false,
    type: 'numeric',
    numericFormat: {
      pattern: '0.00'
    }
  },
  {
    data: 'longBasePrice',
    headerName: 'longBasePrice(Local)',
    readOnly: false,
    allowEmpty: false,
    type: 'numeric',
    numericFormat: {
      pattern: '0.00'
    }
  },
  {
    data: 'shortBasePrice',
    headerName: 'shortBasePrice(Local)',
    readOnly: false,
    allowEmpty: false,
    type: 'numeric',
    numericFormat: {
      pattern: '0.00'
    }
  },
  {
    data: 'upPct',
    headerName: 'UpPct %',
    type: 'numeric',
    readOnly: false,
    allowEmpty: true
  },
  {
    data: 'downPct',
    headerName: 'DownPct %',
    type: 'numeric',
    readOnly: false,
    allowEmpty: true
  }
];

export const priceTraceingColumns = [
  // {
  //   data: 'fundCode',
  //   headerName: 'Fund',
  //   cellClass: 'non-number',
  //   readOnly: true
  // },
  // {
  //   data: 'bookCode',
  //   headerName: 'Book',
  //   readOnly: true,
  //   cellClass: 'non-number'
  // },
  {
    data: 'strategy',
    headerName: 'Strategy',
    cellClass: 'non-number',
    readOnly: true,
    valueFormatter: nullFormatter
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    type: 'autocomplete',
    source(query, process) {
      if (query.length <= 3) return [];
      client.searchTickers(query).then(response => process(response.tickers));
    },
    strict: true
  },
  {
    data: 'securityCode',
    headerName: 'Security',
    readOnly: true,
    allowEmpty: false
  },
  // {
  //   data: 'quantityDirection',
  //   headerName: 'LSF',
  //   renderer: lsfRenderer,
  //   type: 'dropdown',
  //   source: ['LONG', 'SHORT']
  // },
  {
    data: 'notnlMktValBookPct',
    headerName: 'MV %',
    readOnly: true,
    allowEmpty: false,
    type: 'numeric',
    numericFormat: {
      pattern: '0.00 %'
    }
  },
  {
    data: 'avgCostLocal',
    headerName: 'Local Cost',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    type: 'numeric',
    readOnly: true,
    numericFormat: {
      pattern: '0.00'
    }
  },
  {
    data: 'endPriceLocal',
    headerName: 'Local Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    type: 'numeric',
    readOnly: true,
    numericFormat: {
      pattern: '0.00'
    }
  },
  {
    data: 'basePrice',
    headerName: 'Base Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    readOnly: false,
    allowEmpty: true,
    type: 'numeric',
    numericFormat: {
      pattern: '0.00'
    }
  },
  {
    data: 'upPct',
    headerName: 'UpPct %',
    type: 'numeric',
    readOnly: false,
    allowEmpty: true
  },
  {
    data: 'downPct',
    headerName: 'DownPct %',
    type: 'numeric',
    readOnly: false,
    allowEmpty: true
  }
];

export const pxTrackingAlertsColumnDefs = [
  {
    field: 'fundCode',
    headerName: 'Fund',
    enableRowGroup: true,
    hide: true,
    cellClass: 'non-number',
    width: 60
  },
  {
    field: 'bookCode',
    headerName: 'Book',
    enableRowGroup: true,
    hide: true,
    cellClass: 'non-number',
    enablePivot: true,
    width: 50
  },
  {
    field: 'strategy',
    headerName: 'Strategy',
    enableRowGroup: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    width: 70
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    enableRowGroup: true,
    cellClass: 'keyword',
    pinned: 'left',
    lockPinned: true,
    width: 110
  },
  {
    field: 'quantityDirection',
    headerName: 'LSF',
    cellClassRules: {
      long: params => params.value === 'LONG',
      short: params => params.value === 'SHORT',
      flat: params => params.value !== 'LONG' && params.value !== 'SHORT'
    },
    enablePivot: true,
    width: 50
  },
  {
    field: 'basePrice',
    headerName: 'Base Price',
    cellClass: 'number',
    valueFormatter: threeDPFormatter,
    width: 60
  },
  {
    field: 'lastPrice',
    headerName: 'Last Price',
    cellClass: 'number',
    valueFormatter: threeDPFormatter,
    width: 60
  },
  {
    field: 'chgPct',
    headerName: 'Last/base%',
    cellClass: 'number',
    valueFormatter: percentFormatter,
    width: 80
  },
  {
    field: 'upPct',
    headerName: 'Up alert point',
    cellClass: 'number',
    valueFormatter: params => {
      return `${params.value}%`;
    },
    width: 70
  },
  {
    field: 'downPct',
    headerName: 'Down alert point',
    cellClass: 'number',
    valueFormatter: params => {
      return `${params.value}%`;
    },
    width: 80
  }
];

export const pairPxTrackingAlertsColumnDefs = [
  {
    field: 'fundCode',
    headerName: 'Fund',
    enableRowGroup: true,
    hide: true,
    cellClass: 'non-number',
    width: 60
  },
  {
    field: 'bookCode',
    headerName: 'Book',
    enableRowGroup: true,
    hide: true,
    cellClass: 'non-number',
    enablePivot: true,
    width: 50
  },
  {
    field: 'strategy',
    headerName: 'Strategy',
    enableRowGroup: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    width: 70
  },
  {
    field: 'longTicker',
    headerName: 'LongTicker',
    enableRowGroup: true,
    cellClass: 'keyword',
    pinned: 'left',
    lockPinned: true,
    width: 110
  },
  {
    field: 'shortTicker',
    headerName: 'ShortTicker',
    enableRowGroup: true,
    cellClass: 'keyword',
    pinned: 'left',
    lockPinned: true,
    width: 110
  },
  {
    field: 'longShortRatio',
    headerName: 'LongShortRatio',
    enableRowGroup: true,
    cellClass: 'keyword',
    pinned: 'left',
    lockPinned: true,
    width: 110
  },
  {
    field: 'longBasePrice',
    headerName: 'LongBasePrice',
    cellClass: 'number',
    valueFormatter: threeDPFormatter,
    width: 60
  },
  {
    field: 'longLastPrice',
    headerName: 'LongLastPrice',
    cellClass: 'number',
    valueFormatter: threeDPFormatter,
    width: 60
  },
  {
    field: 'shortBasePrice',
    headerName: 'ShortBasePrice',
    cellClass: 'number',
    valueFormatter: threeDPFormatter,
    width: 60
  },

  {
    field: 'shortLastPrice',
    headerName: 'ShortLastPrice',
    cellClass: 'number',
    valueFormatter: threeDPFormatter,
    width: 60
  },
  {
    field: 'pctChg',
    headerName: 'PairPctChg%',
    cellClass: 'number',
    valueFormatter: percentFormatter,
    width: 80
  },
  {
    field: 'upPct',
    headerName: 'Up alert point',
    cellClass: 'number',
    valueFormatter: params => {
      return `${params.value}%`;
    },
    width: 70
  },
  {
    field: 'downPct',
    headerName: 'Down alert point',
    cellClass: 'number',
    valueFormatter: params => {
      return `${params.value}%`;
    },
    width: 80
  }
];

export const priceTricingTradesColumns = [
  {
    data: 'lastPrice',
    headerName: 'Last Price',
    type: 'numeric',
    numericFormat: {
      pattern: '0.00'
    },
    width: 70,
    readOnly: true
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    readOnly: true,
    width: 150
  },
  {
    data: 'quantityDirection',
    headerName: 'LSF',
    renderer: lsfRenderer,
    type: 'dropdown',
    readOnly: true,
    width: 100,
    source: ['LONG', 'SHORT']
  },
  {
    data: 'notnlMktValBookPct',
    headerName: 'Before %',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    },
    readOnly: true,
    width: 100
  },
  {
    data: 'posnEndPct',
    headerName: 'Target %',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    },
    allowEmpty: false,
    width: 100
  },
  {
    data: 'pairTicker',
    headerName: 'Ticker(Pair)',
    type: 'dropdown',
    source: [],
    width: 150
  },
  {
    data: 'pairQuantityDirection',
    headerName: 'LSF(Pair)',
    renderer: lsfRenderer,
    type: 'dropdown',
    readOnly: true,
    width: 100,
    source: ['LONG', 'SHORT']
  },
  // {
  //   data: 'side',
  //   headerName: 'Side',
  //   renderer: sideRenderer,
  //   type: 'dropdown',
  //   width: 100,
  //   source: ['BUY', 'SELL', 'SHRT', 'COVR']
  // },
  {
    data: 'pairNotnlMktValBookPct',
    headerName: 'Before %(Pair)',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    },
    readOnly: true,
    width: 100
  },
  {
    data: 'pairPosnEndPct',
    headerName: 'Target %(Pair)',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    },
    allowEmpty: false,
    width: 100
  }
];

export const orderReviewColumn = [
  {
    field: 'bbgTicker',
    headerName: 'BbgTicker',
    cellClass: 'keyword',
    editable: false,
    width: 150
  },
  {
    field: 'tradeDate',
    headerName: 'TradeDate',
    cellClass: 'non-number',
    width: 100
  },
  {
    field: 'secName',
    headerName: 'SecName',
    cellClass: 'non-number',
    editable: false,
    width: 250
  },
  {
    field: 'side',
    headerName: 'Side',
    editable: false,
    width: 100,
    cellClassRules: sideStyle()
  },
  {
    field: 'qtyShare',
    headerName: 'QtyShare',
    cellClass: 'number',
    editable: false,
    width: 100,
    valueFormatter: moneyFormatter
  },
  {
    field: 'qtyUsd',
    headerName: 'QtyUsd',
    cellClass: 'number',
    editable: false,
    width: 100,
    valueFormatter: moneyFormatter
  },
  {
    field: 'prevQtyFilled',
    headerName: 'Prev QtyFilled',
    cellClass: 'number',
    editable: false,
    width: 100,
    valueFormatter: moneyFormatter
  },
  {
    field: 'qtyFilled',
    headerName: 'QtyFilled',
    cellClass: 'number',
    editable: false,
    width: 100,
    valueFormatter: moneyFormatter
  },
  {
    field: 'pctFilled',
    headerName: 'PctFilled',
    cellClass: 'number',
    editable: false,
    width: 100,
    valueFormatter: percentFormatter,
    cellRenderer: 'percentBarComponent'
  },
  {
    field: 'avgPrcLocal',
    headerName: 'AvgPrcLocal',
    cellClass: 'number',
    editable: false,
    width: 100,
    valueFormatter: twoDPFormatter
  },
  {
    field: 'pmReason',
    headerName: 'PmReason',
    width: 500,
    editable: true,
    cellStyle: params => {
      if (
        params !== null &&
        params.value !== undefined &&
        params.value !== null &&
        params.value.length > 2
      ) {
        return { color: 'black', backgroundColor: '#d38e39' };
      }
    }
  }
];

export const sentimentColumn = [
  {
    field: 'industryName',
    headerName: 'Name',
    cellClass: 'keyword',
    editable: false,
    width: 150,
    hide: true
  },
  {
    field: 'tradeDate',
    headerName: 'TradeDate',
    cellClass: 'non-number',
    valueFormatter: dateFormatter,
    width: 120
  },
  {
    field: 'turnoverScore',
    headerName: 'Turnover',
    cellClass: 'number',
    editable: false,
    // cellRenderer: 'ratingBarTenComponent',
    width: 80,
    cellClassRules: {
      blue0: params => params.value === 0,
      blue1: params => params.value === 1,
      blue2: params => params.value === 2,
      blue3: params => params.value === 3,
      blue4: params => params.value === 4,
      blue5: params => params.value === 5,
      red6: params => params.value === 6,
      red7: params => params.value === 7,
      red8: params => params.value === 8,
      red9: params => params.value === 9,
      red10: params => params.value === 10
    }
  },
  // {
  //   field: 'turnover',
  //   headerName: 'Turnover',
  //   cellClass: 'number',
  //   editable: false,
  //   valueFormatter: percentFormatter,
  //   width: 40,
  // },
  {
    field: 'northboundInflowScore',
    headerName: 'Northbound FLows',
    cellClass: 'number',
    // cellRenderer: 'ratingBarTenComponent',
    editable: false,
    width: 120,
    cellClassRules: {
      blue0: params => params.value === 0,
      blue1: params => params.value === 1,
      blue2: params => params.value === 2,
      blue3: params => params.value === 3,
      blue4: params => params.value === 4,
      blue5: params => params.value === 5,
      red6: params => params.value === 6,
      red7: params => params.value === 7,
      red8: params => params.value === 8,
      red9: params => params.value === 9,
      red10: params => params.value === 10
    }
  },
  // {
  //   field: 'northboundInflow',
  //   headerName: 'Northbound FLows',
  //   cellClass: 'number',
  //   editable: false,
  //   valueFormatter: moneyFormatter,
  //   width: 50,
  // },
  {
    field: 'financingScore',
    headerName: 'Financing',
    // cellRenderer: 'ratingBarTenComponent',
    cellClass: 'number',
    editable: false,
    width: 80,
    cellClassRules: {
      blue0: params => params.value === 0,
      blue1: params => params.value === 1,
      blue2: params => params.value === 2,
      blue3: params => params.value === 3,
      blue4: params => params.value === 4,
      blue5: params => params.value === 5,
      red6: params => params.value === 6,
      red7: params => params.value === 7,
      red8: params => params.value === 8,
      red9: params => params.value === 9,
      red10: params => params.value === 10
    }
  },
  // {
  //   field: 'financing',
  //   headerName: 'Financing',
  //   cellClass: 'number',
  //   editable: false,
  //   valueFormatter: percentFormatter,
  //   width: 40,
  // },
  {
    field: 'totalScore',
    headerName: 'Total',
    // cellRenderer: 'ratingBarTenComponent',
    cellClass: 'number',
    editable: false,
    width: 80,
    cellClassRules: {
      blue0: params => params.value === 0,
      blue1: params => params.value === 1,
      blue2: params => params.value === 2,
      blue3: params => params.value === 3,
      blue4: params => params.value === 4,
      blue5: params => params.value === 5,
      red6: params => params.value === 6,
      red7: params => params.value === 7,
      red8: params => params.value === 8,
      red9: params => params.value === 9,
      red10: params => params.value === 10
    }
  }
];

export const compareColumn = [
  {
    field: 'ticker',
    headerName: 'Ticker',
    cellClass: 'keyword',
    width: 120
  },
  {
    field: 'securityCode',
    headerName: 'Security',
    enablePivot: true,
    enableRowGroup: true,
    cellClass: 'non-number',
    width: 150
  },
  {
    field: 'strategy',
    headerName: 'Strategy',
    enableRowGroup: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    width: 60
  },
  {
    field: 'fundBook1',
    headerName: 'FundBook',
    cellClass: 'keyword',
    editable: false,
    width: 90
  },
  {
    field: 'mvPct1',
    headerName: 'MV %',
    enableValue: true,
    valueFormatter: percentFormatter,
    cellClass: 'number',
    width: 60
  },
  {
    field: 'fundBook2',
    headerName: 'FundBook',
    cellClass: 'keyword',
    editable: false,
    width: 90
  },
  {
    field: 'mvPct2',
    headerName: 'MV %',
    enableValue: true,
    cellClass: 'number',
    valueFormatter: percentFormatter,
    width: 60
  },
  {
    field: 'diff',
    headerName: 'Diff',
    enableValue: true,
    cellClass: 'number',
    valueFormatter: percentFormatter,
    width: 60
  }
];

export const fxSettleReportColumn = [
  {
    field: 'ccy',
    headerName: 'Ccy',
    cellClass: 'keyword',
    enableRowGroup: true,
    rowGroup: true,
    hide: true,
    width: 40
  },
  {
    field: 'settleDate',
    headerName: 'Date',
    cellClass: 'keyword',
    enableRowGroup: true,
    rowGroup: true,
    hide: true,
    valueFormatter: dateFormatterWithNotGroup,
    width: 70
  },
  {
    field: 'custodian',
    headerName: 'Custodian',
    cellClass: 'keyword',
    enableRowGroup: true,
    width: 60
  },
  {
    field: 'minFixingDate',
    headerName: 'Min Fixing',
    cellClass: 'keyword',
    valueFormatter: dateFormatterWithNotGroup,
    width: 80
  },
  {
    field: 'amt',
    headerName: 'Notional',
    cellClass: 'number',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle(),
    width: 100,
    aggFunc: 'sum'
  },
  {
    field: 'usdAmt',
    headerName: 'USD eq.',
    cellClass: 'number',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle(),
    width: 100,
    aggFunc: 'sum'
  },
  {
    field: 'rate',
    headerName: 'Rate',
    cellClass: 'number',
    valueFormatter: fxRateFormatter,
    cellClassRules: numberStyle(),
    width: 70,
    aggFunc: 'max'
  },
  {
    field: 'rateDate',
    headerName: 'Rate Date',
    cellClass: 'non-number',
    valueFormatter: dateFormatterWithNotGroup,
    width: 80,
    aggFunc: 'max'
  },
];

export const fxTradeReportColumn = [
  {
    field: 'date',
    headerName: 'Date',
    cellClass: 'keyword',
    valueFormatter: dateFormatter,
    width: 100
  }
];

export const positionGridColumnMap = EntityMap.fromArray(
  positionGridColumns,
  'field'
);
export const riskBarColumnMap = EntityMap.fromArray(riskBarColumns, 'field');
