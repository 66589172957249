import _ from 'lodash';

const FIELDS = [
  'key',

  'fundCode',
  'bookCode',
  'strategy',
  'ticker',
  'maturityDate',
  'multiplier',
  'roundLotSize'
];

const _createSharedTradeFields = rp => {
  return {
    refId: null,
    tradeId: null,

    lotSize: rp.roundLotSize,
    qtyInput: 'ByShare',
    limitPriceLocal:
      rp.orderType === 'LMT' ? parseFloat(rp.limitPriceLocal) : null,
    orderType: rp.orderType,
    fundCode: rp.fundCode,
    bookCode: rp.bookCode,
    pmStatus: 'SUBMIT',
    pmRemark: rp.pmRemark,
    pmReason: rp.pmReason,
    requestStatus: 'ACTIVE',
    tradeSource: 'IMS',
    strategy: rp.strategy,
    isRiskControlled: false,
    prevFilledQuantity: 0,
    timeInForce: (rp.timeInForce || 'DAY').toUpperCase(),
    requestType: 'TRD',

    algo: {
      code: (rp.algoCode || 'INLINE').toUpperCase()
    }
  };
};

const RolledPosition = {
  createFrom(p, nav, rollInfo, byPos = true) {
    if (!rollInfo) return null;

    const {
      openTicker,
      openMultiplier,
      avgHistSpreadPct,
      closePx,
      spread,
      trackNetQty
    } = rollInfo;
    const spreadPct = !closePx ? null : _.round((spread / closePx) * 100, 2);
    const mvPct = (p.targetNotnlMktValBook / nav) * 100;
    const qtyStart = p.quantityStart;
    const clsQty = p.targetQuantityEndForClose;
    let closeTradeQty = Math.abs(clsQty);
    if (!byPos) {
      if (qtyStart * trackNetQty >= 0) closeTradeQty = 0;
      else {
        const theoCloseTradeQty = Math.min(
          Math.abs(qtyStart),
          Math.abs(trackNetQty)
        );
        // closeTradeQty = Math.max(
        //   theoCloseTradeQty - Math.abs(clsQty - qtyStart),
        //   0
        // );

        // note: trader will always roll pm orders first, then roll W08 order
        // if they break the rule, the below logic is not correct.
        closeTradeQty = Math.min(theoCloseTradeQty, Math.abs(clsQty));
      }
    }

    const clsMvPct = (clsQty / p.targetQuantityEnd) * mvPct;
    const openTradeQty = parseInt(
      closeTradeQty * (p.multiplier / openMultiplier),
      10
    );
    const rollPct =
      clsQty === 0
        ? undefined
        : _.round((closeTradeQty / Math.abs(clsQty)) * 100);

    const rp = {
      ..._.pick(p, FIELDS),
      multiplier: parseInt(p.multiplier, 10),

      qtyStart,
      rollPct,

      clsQty,
      clsMvPct,
      closeTradeSide: clsQty > 0 ? 'SELL' : 'COVR',
      closeTradeQty,
      openTicker: openTicker,
      openMultiplier: openMultiplier,
      openTradeSide: clsQty > 0 ? 'BUY' : 'SHRT',
      openTradeQty,

      avgHistSpreadPct: !avgHistSpreadPct
        ? null
        : _.round(avgHistSpreadPct * 100, 2),
      spreadPct,
      dvd: 0,

      orderType: 'MKT',
      limitPriceLocal: null,
      timeInForce: 'DAY',
      algoCode: 'TWAP',
      pmRemark: '25%',
      // pmReason: '[ROLL]',
      hasErrors: false,
      rollInfo
    };

    return {
      ...rp,
      pmReason: this.calcPmReason(rp)
    };
  },
  calcPmReason(p) {
    const {
      closeTradeSide,
      closeTradeQty,
      rollInfo: { tickerPrefix = 'N.A.' }
    } = p;
    const dir = closeTradeSide === 'SELL' ? 'LR' : 'SR';
    return `[ROLL]: ${dir} ${closeTradeQty} ${tickerPrefix}`;
  },
  toTrades(rp) {
    return [
      rp.closeTradeQty > 0 && {
        ticker: rp.ticker,
        side: rp.closeTradeSide,
        quantity: rp.closeTradeQty,
        qtyPct: Math.abs((rp.clsMvPct / 100) * (rp.closeTradeQty / rp.clsQty)),
        ..._createSharedTradeFields(rp)
      },
      rp.openTradeQty > 0 && {
        ticker: rp.openTicker,
        side: rp.openTradeSide,
        quantity: rp.openTradeQty,
        qtyPct: Math.abs(
          (rp.clsMvPct / 100) *
            (rp.openTradeQty / rp.clsQty) *
            (rp.openMultiplier / rp.multiplier)
        ),
        ..._createSharedTradeFields(rp)
      }
    ].filter(Boolean);
  }
};

export default RolledPosition;
