import React, { Component } from 'react';
import { Button, Modal, Select, DatePicker, Tabs, Spin } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import agGridUtils from '../../../common/ui/agGridUtils';
import {
  getCurrentWorkingDay,
} from '../../../common/utils/DateUtils';
import 'ag-grid-enterprise';
import { DIALOG_FX_SETTLE_REPORT } from '../portfolioConstants';
import { fxSettleReportColumn } from './GridColumnMap';
import moment from 'moment';
import client from '../api/client';
import _ from 'lodash';

const Option = Select.Option;
const { RangePicker } = DatePicker;
class FxSettleReportDialog extends Component {
  constructor(props) {
    super(props);

    const lastWorkingDate = getCurrentWorkingDay();
    this.state = {
      loadStatus: 'READY',
      data: {},
      fundBooks: [],
      date: [lastWorkingDate, moment().add(365, 'days')],
      asOfDate: lastWorkingDate,
      gridSettings: agGridUtils.createSettings({
        columnDefs: fxSettleReportColumn,
        floatingFilter: false,
        defaultColDef: {
          enableCellChangeFlash: false,
          minWidth: 25,
          sortable: true,
          resizable: true,
          filter: true
        },
        deltaRowDataMode: false,
        rowGroupPanelShow: true,
        pivotPanelShow: false,
        sideBar: {
          toolPanels: ['columns', 'filters']
        }
      }),
      autoGroupColumnDef: {
        cellClass: 'non-number',
        width: 100
      },
      isLoaded: false
    };
  }

  closeDialog = () => {
    this.props.closeDialog(DIALOG_FX_SETTLE_REPORT);
  };

  componentDidMount() {
    this._init();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  _init = () => {
    const { ui } = this.props;
    const { funds } = this.props.settings;
    const selectedFundBook = `${ui.selectedFund}-${ui.selectedBook}`;
    const fundBooks = [selectedFundBook];
    const fundBookOptions = [];
    funds.forEach(fundItem => {
      const books = fundItem.books ? fundItem.books : [];
      if (!_.isEmpty(books)) {
        let fundBook = {};
        fundBook.value = fundItem.name;
        fundItem.books.forEach(item => {
          fundBookOptions.push(fundItem.name + '-' + item.name);
        });
      }
    });

    if (_.isEmpty(fundBookOptions)) return;
    this.setState(
      {
        fundBooks,
        fundBookOptions
      },
      this._loadData
    );
  };

  _loadData = () => {
    const { fundBooks, date, asOfDate } = this.state;
    if (!date[0] || !date[1]) return;
    const params = {
      fundBooks,
      startDate: date[0].format('YYYY-MM-DD'),
      endDate: date[1].format('YYYY-MM-DD'),
      asOfDate: asOfDate.format('YYYY-MM-DD'),
    };
    this.setState({
      isLoaded: true
    });
    client
      .getFxTradeData(params)
      .then(resp => {
        this.setState({
          data: resp,
          isLoaded: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isLoaded: false
        });
      });
  };

  onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };


  onInputChange = value => {
    this.setState(
      {
        fundBooks: [value]
      },
      this._loadData
    );
  };

  _onDateChange = date => {
    this.setState(
      {
        date
      },
      this._loadData
    );
  };

  _onAsOfDateChange = date => {
    this.setState(
      {
        asOfDate: date
      },
      this._loadData
    );
  };

  _onOpenChange = open => {
    if (open) {
      this._onDateChange([]);
    }
  };

  disabledDate = current => {
    const { date } = this.state;
    if (!date || date.length === 0) return false;
    return !(current.weekday() > 0 && current.weekday() < 6);
  };

  _createOpBar = () => {
    const { fundBooks, fundBookOptions, date, asOfDate } = this.state;
    if (_.isEmpty(fundBookOptions)) return;
    return (
      <div style={{ textAlign: 'right', width: '100%' }}>
        <Select
          mode="single"
          size="small"
          style={{ marginRight: '5px', width: '200px' }}
          onChange={value => {
            this.onInputChange(value);
          }}
          showSearch={true}
          value={fundBooks}
        >
          {fundBookOptions.map(t => (
            <Option value={t} key={t}>
              {t}
            </Option>
          ))}
        </Select>

        <RangePicker
          size="small"
          disabledDate={this.disabledDate}
          onCalendarChange={this._onDateChange}
          value={date}
          format={'YYYY-MM-DD'}
          allowClear={false}
          style={{ marginRight: '10px' }}
          onOpenChange={this._onOpenChange}
          onChange={this._onDateChange}
        />

      <DatePicker
          size="small"
          value={asOfDate}
          format={'YYYY-MM-DD'}
          allowClear={false}
          style={{ marginRight: '10px' }}
          onChange={(date, dateString) => this._onAsOfDateChange(date)}
        />
        <Button
          size="small"
          type="primary"
          onClick={this._loadData}
          style={{ marginLeft: '15px', marginRight: '30px' }}
        >
          Reload
        </Button>
      </div>
    );
  };

  _createDataGrid = () => {
    const { gridSettings, isLoaded, data } = this.state;
    const dataList = !_.isEmpty(data)?data.filter(r => r.amt !== 0):[];
    const rowData = _.orderBy(dataList.map(r => ({
      ...r,
      rate: r.rate?r.rate:null,
      rateDate: r.rateDate?r.rateDate: null,
      settleDate: _.isEmpty(r.settleDate)?null:moment(r.settleDate).format('YYYY/MM/DD')
    })), ['settleDate'], ['asc']);
    return (
      <Spin tip="Loading..." spinning={isLoaded}>
        {!_.isEmpty(data) && (
          <div
            className={`ag-theme-balham-dark grid-wrapper`}
            style={{ height: '750px', width: '100%' }}
          >
            <AgGridReact
              {...gridSettings}
              rowData={rowData}
              columnDefs={fxSettleReportColumn}
              rowGroupPanelShow={'always'}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              groupDefaultExpanded={1}
              rowSelection="single"
              onGridReady={params => this.onGridReady(params)}
              groupIncludeTotalFooter={false}
              getContextMenuItems={[]}
            />
          </div>
        )}
      </Spin>
    );
  };

  render() {
    return (
      <Modal
        width={'1200px'}
        maskClosable={false}
        visible={true}
        onCancel={this.closeDialog}
        className="fxTradeReportDialog"
        bodyStyle={{
          height: '820px',
          overflowY: 'auto',
          backgroundColor: '#2d3436',
          color: 'white',
          padding: '12px'
        }}
        footer={null}
      >
        <style>
          {` 
                  .fxTradeReportDialog .anticon {
                    color: gray !important;
                  }

                  .fxTradeReportDialog .ant-select .ant-select-selector{
                    background-color:#FF9E28;
                  }

                  .fxTradeReportDialog .ant-picker{
                    background-color:#FF9E28;
                  }
                 
            `}
        </style>
        {this._createOpBar()}
        {this._createDataGrid('settleReport')}
      </Modal>
    );
  }
}

export default FxSettleReportDialog;
