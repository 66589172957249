export const LOAD_SETTINGS_SUCCESS = 'LOAD_SETTINGS_SUCCESS';
export const LOAD_SETTINGS_FAILURE = 'LOAD_SETTINGS_FAILURE';

export const HELP_DIALOG_OPEN = 'HELP_DIALOG_OPEN';
export const HELP_DIALOG_RESET = 'HELP_DIALOG_RESET';

export const USER_SETTING_DIALOG_OPEN = 'USER_SETTING_DIALOG_OPEN';
export const USER_SETTING_DIALOG_RESET = 'USER_SETTING_DIALOG_RESET';
export const OPEN_USER_SETTINGS_SUCCESS = 'OPEN_USER_SETTINGS_SUCCESS';
export const OPEN_USER_SETTINGS_FAILURE = 'OPEN_USER_SETTINGS_FAILURE';
