import React, { PureComponent } from 'react';
import {
    Modal,
    Select,
    Button,
  } from 'antd';
import _ from 'lodash';

const Option = Select.Option;
const _createUIOptions = codes => {
    return codes.map(c => (
      <Option key={c || 'default'} value={c}>
        {c}
      </Option>
    ));
  };

class AssignRuleDialog extends PureComponent {
    constructor(props) {
        super(props);
        const { traders, currentUser } = this.props;
        this.state = {
          submitStatus: 'READY',
          traderCode: traders.includes(currentUser)?currentUser:_.first(traders)
        };
    }

  _onSubmit = () => {
    const { selectedRules } = this.props;
    const { traderCode } = this.state;
    this.props.onSubmit(selectedRules.map(r => ({
        ...r, 
        trader: traderCode
    })) );
  };

  _onInputChange = (value) => {
    this.setState({
        traderCode: value
    })
  }

  _createSelectCompents = () => {
    const { traderCode } = this.state;
    const { traders } = this.props;
    const traderOptions = _createUIOptions(traders);
    return (
        <Select 
            showSearch
            style={{width: '95%'}}
            value={traderCode}
            onChange={value => {
                this._onInputChange(value);
            }}
         >
            {traderOptions}
        </Select>
    )
  }

  _createMsgs = () => {
    const { traderCode } = this.state;
    const { selectedRules } = this.props;
    const ruleRefIds = selectedRules.map(o => o.refId).join(',');
    return (
        <div style={{marginLeft: '5px', marginTop: '15px'}}>
            <p>Are you sure you want to assign the selected rules to {traderCode}?</p>
            <p>{ruleRefIds} </p>
        </div>
    )
  }

  closeDialog = () => {
    this.props.closeDialog()
  }

  _createSubmitBtn = () => {
    const { submitStatus } = this.state;
    return {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={this._onSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={this._onSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];
  };

  render() {
    return (
      <div>
        <Modal 
            visible={true}
            width={1000}
            style={{ marginTop: '30px' }}
            title={'Assign Rules'}
            onCancel={this.closeDialog}
            footer={[
                this._createSubmitBtn(),
                <Button key="close" type="primary" onClick={this.closeDialog}>
                  Cancel
                </Button>
              ]}
            >
            {this._createSelectCompents()}
            {this._createMsgs()}
        </Modal>
      </div>
    );
  }
}

export default AssignRuleDialog;
