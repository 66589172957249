import {
  moneyFormatter,
  percentFormatter,
  riskControlLimitFormatter,
  twoDPFormatter,
  nullFormatter,
  threeDPFormatter,
  percentFormatterWithSixPercent
} from '../util/valueFormatters';
import {
  percentStyle,
  statusStyle,
  returnStyle
} from 'common/utils/styleChooser';
import EntityMap from 'entities/EntityMap';
import { positionGridColumnMap } from 'features/portfolio/components/GridColumnMap';

const riskCheckGridColumns = [
  {
    field: 'checkType',
    headerName: 'Check Type',
    enableRowGroup: true,
    cellClass: 'keyword',
    width: 175
  },
  {
    field: 'limitChecksDesc',
    headerName: 'Limits',
    enableRowGroup: true,
    cellClass: 'non-number',
    width: 275
  },
  {
    field: 'value',
    headerName: 'Value',
    cellClass: 'non-number',
    width: 200
  },
  {
    field: 'status',
    headerName: 'Status',
    enableRowGroup: true,
    width: 100,
    cellClassRules: statusStyle()
  }
];

const riskGridColumns = [
  {
    field: 'fundCode',
    headerName: 'Fund',
    enableRowGroup: true,
    cellClass: 'keyword',
    pinned: 'left'
  },
  {
    field: 'bookCode',
    headerName: 'Book',
    enableRowGroup: true,
    cellClass: 'keyword',
    pinned: 'left'
  },
  {
    field: 'aggregateLevel',
    headerName: 'Level',
    enableRowGroup: true,
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'nav',
    headerName: 'NAV',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClass: 'number'
  },
  {
    field: 'dtdRet',
    headerName: 'DTD',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    enableValue: true,
    cellClassRules: percentStyle()
  },
  {
    field: 'dtdFxRet',
    headerName: 'DTD FX',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    enableValue: true,
    cellClassRules: percentStyle()
  },
  {
    field: 'dtdAsiaRet',
    headerName: 'DTD ASIA',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    enableValue: true,
    cellClassRules: percentStyle()
  },
  {
    field: 'dtdNonAsiaRet',
    headerName: 'DTD NON-ASIA',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    enableValue: true,
    cellClassRules: percentStyle()
  },
  {
    field: 'mtdRet',
    headerName: 'MTD',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    enableValue: true,
    cellClassRules: percentStyle()
  },
  {
    field: 'ytdRet',
    headerName: 'YTD',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    enableValue: true,
    cellClassRules: percentStyle()
  },
  {
    field: 'pnl3DaysStr',
    headerName: 'Pnl3Days',
    cellClass: 'number'
  },
  {
    field: 'pnl5DaysStr',
    headerName: 'Pnl5Days',
    cellClass: 'number'
  },
  {
    field: 'numOfNames',
    headerName: 'Names',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    hide: true
  },
  {
    field: 'maxDD',
    headerName: 'Max DD',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    cellClass: 'number'
  },
  {
    field: 'currDD',
    headerName: 'Curr DD',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    cellClass: 'number'
  },
  {
    field: 'curr1YDD',
    headerName: 'Curr1Y DD',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    cellClass: 'number'
  },
  {
    field: 'relYtdRet',
    headerName: 'Rel YTD',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    enableValue: true,
    cellClassRules: percentStyle()
  },
  {
    field: 'relMaxDD',
    headerName: 'Rel Max DD',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    cellClass: 'number'
  },
  {
    field: 'relCurrDD',
    headerName: 'Rel Curr DD',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    cellClass: 'number'
  },
  {
    field: 'gt2Days',
    headerName: '>2Days',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'maxSingleName',
    headerName: 'Max Single',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'longEquityBetaPct',
    headerName: 'L-Eqtyβ',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'shortEquityBetaPct',
    headerName: 'S-Eqtyβ',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'netEquityBetaPct',
    headerName: 'N-Eqtyβ',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'grossEquityBetaPct',
    headerName: 'G-Eqtyβ',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'longEquityPct',
    headerName: 'L-Eqty',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'shortEquityPct',
    headerName: 'S-Eqty',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'grossEquityPct',
    headerName: 'G-Eqty',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'netEquityPct',
    headerName: 'N-Eqty',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'grossPct',
    headerName: 'Gross',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'netPct',
    headerName: 'Net',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'netGrossRatio',
    headerName: 'N-Eqtyβ/G',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  // {
  //   field: 'alpha',
  //   headerName: 'Alpha',
  //   // cellRenderer: 'agAnimateShowChangeCellRenderer',
  //   valueFormatter: percentFormatter,
  //   enableValue: true,
  //   cellClassRules: percentStyle()
  // },
  // {
  //   field: 'beta',
  //   headerName: 'Beta',
  //   // cellRenderer: 'agAnimateShowChangeCellRenderer',
  //   cellClass: 'number',
  //   valueFormatter: twoDPFormatter
  // },
  {
    field: 'sharpe',
    headerName: 'Sharpe',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: twoDPFormatter
  },
  {
    field: 'annVol',
    headerName: 'AnnVol',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    enableValue: true,
    cellClassRules: percentStyle()
  },
  {
    field: 'turnover30D',
    headerName: 'TurnoverM',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    enableValue: true,
    cellClassRules: percentStyle()
  },
  {
    field: 'turnover252D',
    headerName: 'TurnoverY',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    enableValue: true,
    cellClassRules: percentStyle()
  },
  // {
  //   field: 'targetLongEquityBetaPct',
  //   headerName: 'TGT L-Eqtyβ',
  //   cellRenderer: 'percentBarComponent',
  //   cellRendererParams: {
  //     factor: 1
  //   },
  //   enableValue: true,
  //   cellClass: 'number',
  //   hide: true
  // },
  // {
  //   field: 'targetShortEquityBetaPct',
  //   headerName: 'TGT S-Eqtyβ',
  //   cellRenderer: 'percentBarComponent',
  //   cellRendererParams: {
  //     factor: 1
  //   },
  //   enableValue: true,
  //   cellClass: 'number',
  //   hide: true
  // },
  {
    field: 'targetNetEquityBetaPct',
    headerName: 'TGT N-Eqtyβ',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'targetGrossEquityPct',
    headerName: 'TGT G-Eqty',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'riskControlLimit',
    headerName: 'Risk LMT',
    valueFormatter: riskControlLimitFormatter,
    cellStyle: params => {
      const style = { fontWeight: 'bold' };
      return {
        ...style,
        backgroundColor:
          params.value === undefined ? '' : !!params.value ? 'red' : 'royalblue'
      };
    },
    hide: true
  },
  {
    field: 'riskControlDate',
    headerName: 'Date',
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'netBetaPctForCntryCN',
    headerName: 'Net Eqty β CN',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'netBetaPctForCntryHK',
    headerName: 'Net Eqty β HK',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'netBetaPctForCntryUS',
    headerName: 'Net Eqty β US',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'netBetaPctForCntryJP',
    headerName: 'Net Eqty β JP',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'netBetaPctForCntryTW',
    headerName: 'Net Eqty β TW',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'netBetaPctForCntryIN',
    headerName: 'Net Eqty β IN',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'netBetaPctForCntryKR',
    headerName: 'Net Eqty β KR',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'netBetaPctForCntrySG',
    headerName: 'Net Eqty β SG',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'netBetaPctForCntryEU',
    headerName: 'Net Eqty β EU',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'netBetaPctForCntryUS_ADR',
    headerName: 'Net Eqty β ADR',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'netBetaPctForCntryOTHERS',
    headerName: 'Net Eqty β Others',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
  {
    field: 'riskCheckStatus',
    headerName: 'Risk Status',
    valueFormatter: nullFormatter,
    cellRenderer: 'tableTooltipComponent',
    cellRendererParams: {
      tooltipTitle: 'Risk Checks',
      tooltipField: 'riskCheckResults',
      tooltipColumnDefs: riskCheckGridColumns,
      tooltipWidth: '750px',
      tooltipHeight: '300px'
    },
    cellClassRules: statusStyle()
  },
  {
    field: 'dtdTxnRet',
    headerName: 'DTD TXN',
    valueFormatter: percentFormatter,
    enableValue: true,
    cellClassRules: percentStyle()
  },
  {
    field: 'officeLocation',
    headerName: 'Location',
    cellClass: 'non-number',
    enableRowGroup: true
  },
  {
    field: 'strategy',
    headerName: 'Strategy',
    cellClass: 'non-number',
    enableRowGroup: true
  },
  {
    field: 'englishName',
    headerName: 'PM',
    cellClass: 'non-number',
    enableRowGroup: true
  },
  {
    field: 'advisorName',
    headerName: 'Advisor',
    cellClass: 'non-number',
    enableRowGroup: true
  },
  {
    field: 'avgGrossPct',
    headerName: 'Avg Gross %',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 1
    },
    enableValue: true,
    cellClass: 'number',
    hide: true
  },
];

const exposureGridGroupColumns = {
  exchangeCountryCode: 0,
  sectorCode: 1
};

const exposureGridPivotColumns = {
  quantityDirection: 0
};

const exposureGridColumns = EntityMap.map(positionGridColumnMap)
  .filter(c =>
    [
      'bookCode',
      'exchangeCountryCode',
      'countryCode',
      'sectorCode',
      'instrumentClass',
      'liquidityCategory',
      'marketCapCategory',
      'quantityDirection',
      'notnlMktValBookPct',
      'betaNotnlMktValBookPct',
      'dtdPnlPct',
      'mtdPnlPct',
      'ytdPnlPct'
    ].includes(c.field)
  )
  .map(c => ({
    ...c,
    pivotIndex: exposureGridPivotColumns[c.field],
    rowGroupIndex: exposureGridGroupColumns[c.field],
    width: 60
  }));
const riskGridColumnMap = EntityMap.fromArray(riskGridColumns, 'field');

const benchmarkGridColumns = [
  {
    field: 'ticker',
    headerName: 'Ticker',
    enableRowGroup: true,
    cellClass: 'keyword',
    pinned: 'left'
  },
  {
    field: 'code',
    headerName: 'Name',
    enableRowGroup: true,
    cellClass: 'non-number'
  },
  {
    field: 'country',
    headerName: 'Country',
    enableRowGroup: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    enablePivot: true
  },
  {
    field: 'price',
    headerName: 'Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: twoDPFormatter
  },
  {
    field: 'dtdPriceChange',
    headerName: 'DTD',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    enableValue: true,
    cellClassRules: percentStyle()
  }
];

const riskReportInfoColumns = [
  {
    field: 'fund',
    headerName: 'Fund',
    cellClass: 'keyword',
    pinned: 'left'
  },
  {
    field: 'book',
    headerName: 'Book',
    cellClass: 'keyword',
    pinned: 'left'
  },
  {
    field: 'detail.Market_Timing',
    headerName: 'Market Timing',
    cellClass: 'number',
    valueFormatter: percentFormatter,
    hide: true
  },
  {
    field: 'leverageRatio',
    headerName: 'Leverage Ratio',
    cellClass: 'number',
    valueFormatter: nullFormatter
  },
  {
    field: 'aum',
    headerName: 'Aum',
    cellClass: 'number',
    valueFormatter: moneyFormatter
  },
  {
    field: 'absMktValue',
    headerName: 'GMV',
    cellClass: 'number',
    valueFormatter: moneyFormatter
  },
  {
    field: 'detail.Total',
    headerName: 'Total',
    cellClass: 'number',
    valueFormatter: percentFormatter
  },
  {
    field: 'detail.Residual',
    headerName: 'Residual',
    cellClass: 'number',
    valueFormatter: percentFormatter
  },
  {
    field: 'detail.Common_Factor',
    headerName: 'Common Factor',
    cellClass: 'number',
    valueFormatter: percentFormatter
  },
  {
    field: 'detail.Industry',
    headerName: 'Industry',
    cellClass: 'number',
    valueFormatter: percentFormatter
  },
  {
    headerName: 'Risk Indices',
    cellClass: 'number',
    groupId: 'riskIndicesGroup',
    children: [
      {
        field: 'detail.Risk_Indices',
        headerName: 'Risk Indices',
        cellClass: 'number',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Beta',
        headerName: 'Beta',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Book-to-Price',
        headerName: 'Book-to-Price',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Dividend_Yield',
        headerName: 'Dividend Yield',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Earnings_Quality',
        headerName: 'Earnings Quality',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Earnings_Variability',
        headerName: 'Earnings Variability',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Earnings_Yield',
        headerName: 'Earnings Yield',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Growth',
        headerName: 'Growth',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Investment_Quality',
        headerName: 'Investment Quality',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Leverage',
        headerName: 'Leverage',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Liquidity',
        headerName: 'Leverage',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Long-Term_Reversal',
        headerName: 'Long-Term Reversal',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Mid_Capitalization',
        headerName: 'Mid Capitalization',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Momentum',
        headerName: 'Momentum',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Residual_Volatility',
        headerName: 'Residual Volatility',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Size',
        headerName: 'Size',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      }
    ]
  },
  {
    field: 'detail.Country',
    headerName: 'Country',
    cellClass: 'number',
    columnGroupShow: 'open',
    valueFormatter: percentFormatter
  },
  {
    field: 'detail.World',
    headerName: 'World',
    cellClass: 'number',
    columnGroupShow: 'open',
    valueFormatter: percentFormatter
  },
  {
    field: 'detail.Factor_Interaction',
    headerName: 'Factor Interaction',
    cellClass: 'number',
    valueFormatter: percentFormatter,
    hide: true
  },
  {
    field: 'detail.Specific',
    headerName: 'Specific',
    cellClass: 'number',
    valueFormatter: percentFormatter
  },
  {
    field: 'detail.SpecificCP',
    headerName: 'Specific Risk%Contribution',
    cellClass: 'number',
    valueFormatter: percentFormatter
  }
];

const riskReportAttributeColumns = [
  {
    field: 'activeRisk',
    headerName: 'Risk',
    cellClass: 'number',
    valueFormatter: percentFormatterWithSixPercent
  },
  {
    field: 'activeRiskContribution',
    headerName: 'Risk Contribution',
    cellClass: 'number',
    valueFormatter: percentFormatter
  },
  {
    field: 'activeRiskPercent',
    headerName: 'Risk % Contribution',
    cellClass: 'number',
    valueFormatter: percentFormatter
  },
  {
    field: 'activeCorrelation',
    headerName: 'Correlation',
    cellClass: 'number',
    valueFormatter: nullFormatter
  },
  {
    field: 'activeResidualExposure',
    headerName: 'Exposure',
    cellClass: 'number'
  },
  {
    field: 'activeVolatility',
    headerName: 'Volatility',
    cellClass: 'number',
    valueFormatter: percentFormatter
  }
];

const riskReportAssetColumns = [
  {
    field: 'assetId',
    headerName: 'Asset Id',
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'barraId',
    headerName: 'Barra Id',
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    cellClass: 'keyword',
    pinned: 'left'
  },
  {
    field: 'assetName',
    headerName: 'Asset Name',
    cellClass: 'non-number'
  },
  {
    field: 'weight',
    headerName: 'Weight',
    cellClass: 'number',
    valueFormatter: percentFormatterWithSixPercent,
    cellClassRules: percentStyle()
  },
  {
    field: 'mktValue',
    headerName: 'Mkt Value',
    cellClass: 'number',
    valueFormatter: nullFormatter
  },
  {
    field: 'risk',
    headerName: 'Risk',
    cellClass: 'number',
    valueFormatter: percentFormatter
  },
  {
    field: 'exposure',
    headerName: 'Exposure',
    cellClass: 'number',
    valueFormatter: nullFormatter
  }
];

const returnReportInfoColumns = [
  {
    field: 'fund',
    headerName: 'Fund',
    cellClass: 'keyword',
    pinned: 'left'
  },
  {
    field: 'book',
    headerName: 'Book',
    cellClass: 'keyword',
    pinned: 'left'
  },
  {
    field: 'detail.Market_Timing',
    headerName: 'Market Timing',
    cellClass: 'number',
    valueFormatter: percentFormatter,
    hide: true
  },
  {
    field: 'detail.Total_Active',
    headerName: 'Total Active',
    cellClass: 'number',
    valueFormatter: percentFormatter,
    cellClassRules: returnStyle()
  },
  {
    field: 'detail.Residual',
    headerName: 'Residual',
    cellClass: 'number',
    valueFormatter: percentFormatter
  },
  {
    field: 'detail.Common_Factor',
    headerName: 'Common Factor',
    cellClass: 'number',
    valueFormatter: percentFormatter
  },
  {
    field: 'detail.Industry',
    headerName: 'Industry',
    cellClass: 'number',
    valueFormatter: percentFormatter
  },
  {
    headerName: 'Risk Indices',
    cellClass: 'number',
    groupId: 'riskIndicesGroup',
    children: [
      {
        field: 'detail.Risk_Indices',
        headerName: 'Risk Indices',
        cellClass: 'number',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Beta',
        headerName: 'Beta',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Book-to-Price',
        headerName: 'Book-to-Price',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Dividend_Yield',
        headerName: 'Dividend Yield',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Earnings_Quality',
        headerName: 'Earnings Quality',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Earnings_Variability',
        headerName: 'Earnings Variability',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Earnings_Yield',
        headerName: 'Earnings Yield',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Growth',
        headerName: 'Growth',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Investment_Quality',
        headerName: 'Investment Quality',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Leverage',
        headerName: 'Leverage',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Liquidity',
        headerName: 'Leverage',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Long-Term_Reversal',
        headerName: 'Long-Term Reversal',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Mid_Capitalization',
        headerName: 'Mid Capitalization',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Momentum',
        headerName: 'Momentum',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Residual_Volatility',
        headerName: 'Residual Volatility',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      },
      {
        field: 'detail.Size',
        headerName: 'Size',
        cellClass: 'number',
        columnGroupShow: 'open',
        valueFormatter: percentFormatter
      }
    ]
  },
  {
    field: 'detail.Country',
    headerName: 'Country',
    cellClass: 'number',
    columnGroupShow: 'open',
    valueFormatter: percentFormatter
  },
  {
    field: 'detail.World',
    headerName: 'World',
    cellClass: 'number',
    columnGroupShow: 'open',
    valueFormatter: percentFormatter
  },
  {
    field: 'detail.Factor_Interaction',
    headerName: 'Factor Interaction',
    cellClass: 'number',
    valueFormatter: percentFormatter,
    hide: true
  },
  {
    field: 'detail.Specific',
    headerName: 'Specific',
    cellClass: 'number',
    valueFormatter: percentFormatter
  }
];

const returnReportAttributeColumns = [
  {
    field: 'returnNet',
    headerName: 'Return',
    cellClass: 'number',
    valueFormatter: percentFormatterWithSixPercent,
    cellClassRules: returnStyle()
  },
  {
    field: 'riskNet',
    headerName: 'Realized Risk',
    cellClass: 'number',
    valueFormatter: percentFormatterWithSixPercent
  },
  {
    field: 'informationRatio',
    headerName: 'Information Ratio',
    cellClass: 'number',
    valueFormatter: threeDPFormatter
  },
  {
    field: 'tstat',
    headerName: 'T-Stat',
    cellClass: 'number',
    valueFormatter: threeDPFormatter
  }
];

const returnReportAssetColumns = [
  {
    field: 'assetId',
    headerName: 'Asset Id',
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'barraId',
    headerName: 'Barra Id',
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    cellClass: 'keyword',
    pinned: 'left'
  },
  {
    field: 'assetName',
    headerName: 'Asset Name',
    cellClass: 'non-number'
  },
  {
    field: 'avgWeight',
    headerName: 'Avg Weight',
    cellClass: 'number',
    valueFormatter: percentFormatterWithSixPercent,
    cellClassRules: percentStyle()
  },
  {
    field: 'avgMktValue',
    headerName: 'AvgMkt Value',
    cellClass: 'number',
    valueFormatter: moneyFormatter
  },
  {
    field: 'returnValue',
    headerName: 'Return',
    cellClass: 'number',
    valueFormatter: percentFormatterWithSixPercent
  },
  {
    field: 'avgExposure',
    headerName: 'Avg Exposure',
    cellClass: 'number',
    valueFormatter: nullFormatter
  }
];

const reportGridColumns = [
  {
    field: 'rptName',
    headerName: 'Report Name',
    cellClass: 'keyword',
    enableRowGroup: true
  },
  {
    field: 'team',
    headerName: 'Team',
    cellClass: 'keyword',
    enableRowGroup: true
  },
  {
    field: 'params',
    headerName: 'Params',
    cellClass: 'non-number',
    cellRenderer: 'jsonTooltipComponent',
    hide: true
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    cellClass: 'non-number'
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    cellClass: 'non-number'
  },
  {
    field: 'createTime',
    headerName: 'Create Time',
    cellClass: 'non-number'
  },
  {
    field: 'completeTime',
    headerName: 'Complete Time',
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'createBy',
    headerName: 'Run By',
    cellClass: 'non-number'
  },
  {
    field: 'status',
    headerName: 'Status',
    cellClass: 'non-number',
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        FAIL: 'red',
        WORKING: 'yellow',
        PASS: '#16ab39'
      }
    }
  }
];

export {
  riskGridColumnMap,
  exposureGridColumns,
  benchmarkGridColumns,
  riskCheckGridColumns,
  riskReportInfoColumns,
  riskReportAttributeColumns,
  riskReportAssetColumns,
  returnReportInfoColumns,
  returnReportAttributeColumns,
  returnReportAssetColumns,
  reportGridColumns
};
