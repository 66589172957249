import React, { Component } from 'react';
import { Modal, Button, Upload, Spin, Menu, Dropdown } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Message } from 'semantic-ui-react';
import _ from 'lodash';
import { DIALOG_EXECUTE_QUANT_TRADES } from '../../orderConstants';
import { tradeGridColumns, fxTradeGridColumns } from './GridColumnMap';
import { HotTable } from '@handsontable/react';
import hotTableUtils from 'common/ui/hotTableUtils';
import Trade from '../../entities/Trade';
import client from '../../api/client';
import createOrderFileParser from 'common/utils/orderFileParser';
import Papa from 'papaparse';
import TradeCalculator from 'features/order/entities/calculators/TradeCalculator';
import TradeValidator from 'features/order/entities/validators/TradeValidator';
import config from 'common/config';
import numeral from 'numeral';
import hyperid from 'hyperid';
import { roleTradeLabel } from 'common/utils/DomainUtils';

const uniqidInstance = hyperid();
const tempalteFileName = 'TRADE_SAMPLE_TEMPLATE.csv';
const tempalteFxFileName = 'TRADE_SAMPLE_TEMPLATE_FX.csv';

const csvParseConfig = {
  dynamicTyping: true,
  encoding: 'GB2312',
  skipEmptyLines: true,
  worker: false,
  header: true
};

const columns = [
  ...tradeGridColumns.map(r => {
    if (r.data === 'algoCode') {
      return {
        ...r,
        type: 'dropdown',
        source: ['INLINE', 'TWAP', 'VWAP', 'DMA']
      };
    } else if (r.data === 'orderType') {
      return {
        ...r,
        type: 'dropdown',
        source: ['LMT', 'MKT']
      };
    }
    return { ...r };
  }),
  {
    data: 'basket',
    headerName: 'Basket',
    readOnly: false
  }
];

class QuantTradesDialog extends Component {
  state = {
    isLoading: false,

    submitStatus: 'READY',
    serverErrMsg: '',
    verificationErrs: {},

    rawTrades: [],
    trades: [],
    tradeFiles: [],
    securityMap: {},

    liveRiskInfoMap: {},

    gridWrapperStyle: {
      width: '100%',
      height: '500px',
      marginTop: '20px'
    },
    hotTableSettings: hotTableUtils.createSettings({
      columns: columns,
      rowHeaders: true,
      contextMenu: ['remove_row']
    }),
    fxHotTableSettings: hotTableUtils.createSettings({
      columns: fxTradeGridColumns,
      rowHeaders: true,
      contextMenu: ['remove_row']
    })
  };

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated._
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    this._debouncedSubmit = _.debounce(this.onFormSubmit, 500);

    const { liveRiskInfos, trades, info = {} } = this.props;
    const liveRiskInfoMap = _.keyBy(
      liveRiskInfos,
      r => `${r.fundCode}-${r.bookCode}`
    );

    const tradeList = trades || info.trades;
    const showUpload = _.isEmpty(tradeList);
    this.setState({ liveRiskInfoMap, showUpload });
    this._onParseTrades(tradeList);
  }

  closeDialog = () => {
    this.props.closeDialog(DIALOG_EXECUTE_QUANT_TRADES);
  };

  onFormSubmit = () => {
    const { securityMap, trades } = this.state;

    if (_.isEmpty(trades)) {
      this.closeDialog();
      return;
    }

    const verificationErrs = this._validateTrades(trades);
    const hasErrors = Object.values(verificationErrs).some(tradeErrors =>
      Object.values(tradeErrors).some(
        fieldError => fieldError && !fieldError.isWarning
      )
    );
    if (hasErrors) {
      this.setState({ verificationErrs });
      return;
    }

    const uuid = uniqidInstance();
    const tradeModels = trades
      .filter(t => t.quantity > 0)
      .map(t => {
        const qtyInput = t.qtyInput === 'ByPosPct' ? 'ByPct' : t.qtyInput;
        const pmRemark = t.pmRemark ? _.toString(t.pmRemark) : '';

        const mergedFields = {
          ...t,
          qtyInput,
          pmRemark,
          basket: t.basket || t.basket === 0 ? `${t.basket}-${uuid}` : null
        };

        return Trade.toModel(mergedFields, securityMap[t.ticker], false);
      });

    const { submitDialogSuccess, info } = this.props;

    this.setState({ submitStatus: 'SUBMITTING' });
    client
      .addTrades(tradeModels)
      .then(result => {
        if (info.createNew) {
          _.zip(tradeModels, result).forEach(([t, r]) => {
            t.tradeId = r.id;
            t.refId = r.refId;
          });
        }

        submitDialogSuccess(DIALOG_EXECUTE_QUANT_TRADES, {
          trades: tradeModels,
          createNew: info.createNew
        });
        this._exeCallback();
        this.setState({ submitStatus: 'READY' });
      })
      .catch(_ => {
        this.setState({
          submitStatus: 'ERROR',
          serverErrMsg: 'Server Error! Please contact system administrator.'
        });
      });
  };

  _exeCallback = () => {
    const { info = {} } = this.props;
    const callback = info ? info.callback : null;
    if (callback) {
      callback();
    }
  };

  _calcTrade = (trade, security, name, value) => {
    const { liveRiskInfoMap } = this.state;
    const { fundBooks } = this.props.settings;
    const qtyInput = TradeCalculator.getQtyInputByFieldName(name);

    if (qtyInput) {
      const extraFields = TradeCalculator.calcAll(
        {
          ...trade,
          qtyInput,
          [name]: value
        },
        security,
        liveRiskInfoMap,
        fundBooks
      );

      return {
        ...trade,
        [name]: value,
        ...extraFields
      };
    }

    return {
      ...trade,
      [name]: value
    };
  };

  _validateTrades = trades => {
    const { securityMap } = this.state;
    return this._innerValidateTrades(trades, securityMap);
  };

  _innerValidateTrades = (trades, securityMap) => {
    const { liveRiskInfoMap } = this.state;
    const { fundBooks, user } = this.props.settings;

    return _.fromPairs(
      trades.map(t => {
        const security = securityMap[t.ticker];
        let errors = t.quantity
          ? TradeValidator.validate(
              t,
              security,
              liveRiskInfoMap,
              '',
              fundBooks,
              user,
              trades
            )
          : {};
        if (errors) {
          const tifCheck = this._checkTIF(t);
          errors = {
            ...errors,
            ...tifCheck
          };
        }
        return [t.key, errors];
      })
    );
  };

  _checkTIF = item => {
    if (
      !item.timeInForce ||
      (item.timeInForce !== 'DAY' && item.timeInForce !== 'GTC')
    ) {
      return {
        timeInForce: {
          isWarning: false,
          msg: 'TIF value must be DAY or GTC.'
        }
      };
    }
    return {
      timeInForce: null
    };
  };

  _beforeCellChange = (changes, source) => {
    const { securityMap, verificationErrs } = this.state;
    let { trades } = this.state;
    const updatedTrades = changes
      .map(c => {
        const [row, field, oldValue, newValue] = c;
        if (oldValue === newValue) return null;

        const trade = trades[row];
        const security = securityMap[trade.ticker];
        return this._calcTrade(trade, security, field, newValue);
      })
      .filter(Boolean);

    const updatedTradeMap = _.keyBy(updatedTrades, ut => ut.key);
    trades = trades.map(t => {
      const ut = updatedTradeMap[t.key];
      return ut || t;
    });

    const errorsByKey = this._validateTrades(updatedTrades);

    this.setState({
      trades,
      verificationErrs: {
        ...verificationErrs,
        ...errorsByKey
      }
    });

    return false;
  };

  _beforeRemoveRow = (row, count, rows, source) => {
    const { trades } = this.state;
    const leftTrades = trades
      .filter((_, i) => !rows.includes(i))
      .map((t, i) => ({ ...t, key: i + 1 }));
    const verificationErrs = this._validateTrades(leftTrades);

    _.delay(() => {
      this.setState({
        trades: leftTrades,
        verificationErrs
      });
    }, 100);
  };

  _createTradesGrid = () => {
    const {
      gridWrapperStyle,
      hotTableSettings,
      fxHotTableSettings,
      trades
    } = this.state;
    const isFxTrades = !_.isEmpty(trades) && _.some(trades, r => r.symbol);
    const settings = isFxTrades ? fxHotTableSettings : hotTableSettings;

    return (
      !_.isEmpty(trades) && (
        <div style={gridWrapperStyle}>
          <HotTable
            data={trades}
            beforeChange={this._beforeCellChange}
            beforeRemoveRow={this._beforeRemoveRow}
            manualColumnResize={true}
            {...settings}
          ></HotTable>
        </div>
      )
    );
  };

  _onParseTrades = trades => {
    const {
      settings: { funds }
    } = this.props;

    if (!_.isEmpty(trades)) {
      const inputs = trades.map(t => ({
        ticker: t.ticker,
        tickerType: t.tickerType
      }));
      client.getSecurities(inputs).then(securities => {
        const securityByTicker = _.keyBy(
          securities.filter(Boolean),
          s => s.rawTicker
        );
        const filteredTrades = trades
          .filter(t => {
            const isTickerValid = securityByTicker[t.ticker] != null;
            const matchedFund = _.find(funds, f => f.name === t.fundCode);
            const matchedBook = _.find(
              (matchedFund || {}).books,
              b => b.name === t.bookCode && b.enableTxn
            );

            return isTickerValid && matchedBook;
          })
          .map((t, i) => {
            const security = securityByTicker[t.ticker];
            const enrichedTrade = {
              ...t,
              key: i + 1,
              ticker: security.ticker,
              lotSize: security.lotSize,
              secCcy: t.secCcy ? t.secCcy : security.secCcy,
              txnClass: t.txnClass ? t.txnClass : security.txnClass
            };

            const qtyInputField =
              TradeCalculator.calcQtyInputField(enrichedTrade) || 'quantity';
            return this._calcTrade(
              enrichedTrade,
              security,
              qtyInputField,
              _.get(enrichedTrade, qtyInputField)
            );
          });

        const securityMap = _.keyBy(securities.filter(Boolean), s => s.ticker);
        this._completeParseTrades(filteredTrades, securityMap, trades);
      });
    } else {
      this._completeParseTrades();
    }
  };

  _completeParseTrades = (trades = [], securityMap = {}, rawTrades = []) => {
    const verificationErrs = this._innerValidateTrades(trades, securityMap);

    this.setState({
      isLoading: false,
      trades,
      securityMap,
      rawTrades,
      serverErrMsg: '',
      verificationErrs
    });
  };

  _parseTradeFile = file => {
    this.setState({
      tradeFiles: [file],
      isLoading: true,
      trades: []
    });

    Papa.parse(file, {
      ...csvParseConfig,
      complete: result => {
        const data = _.get(result, 'data', []).filter(r => !_.isNil(r.Fund));
        const isFxTemplate = _.some(data, r => r.Symbol);
        let trades = _.isEmpty(data)
          ? []
          : isFxTemplate
          ? createOrderFileParser('FX_TEMPLATE').parse(data)
          : createOrderFileParser('DEFAULT').parse(data);
        trades = isFxTemplate
          ? trades.filter(r => Trade.isFXTrade(r.txnClass))
          : trades;
        this._onParseTrades(trades);
      }
    });

    return false;
  };

  downloadFile = fileName => {
    const templateFileUrl = `${config.api.REST_URL}/trade/downloadFile?file=${fileName}`;
    const link = document.createElement('a');
    link.href = templateFileUrl;
    link.download = fileName;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
  };

  _createOperationBar = () => {
    const { tradeFiles, showUpload } = this.state;
    const downloadFxTemplete = (
      <Menu
        onClick={() => {
          this.downloadFile(tempalteFxFileName);
        }}
      >
        <Menu.Item key="fXTemplate">FX Template</Menu.Item>
      </Menu>
    );
    return (
      showUpload && (
        <div className="orderDialogOperationBar">
          <div className="rightOperations">
            {/* <a
              style={{ color: 'white' }}
              href={templateFileUrl}
              target="_blank"
              rel="noopener noreferrer"
            >  */}
            <Dropdown.Button
              type="primary"
              shape="round"
              overlay={downloadFxTemplete}
              onClick={() => {
                this.downloadFile(tempalteFileName);
              }}
              style={{ marginLeft: '5px' }}
            >
              downloadTemplate
            </Dropdown.Button>
            {/* <Button type="primary" shape="round" icon="download">
                Template
              </Button> */}
            {/* </a> */}
            <Upload fileList={tradeFiles} beforeUpload={this._parseTradeFile}>
              <Button>
                <UploadOutlined />
                Click to Upload
              </Button>
            </Upload>
          </div>
        </div>
      )
    );
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus } = this.state;
    return {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];
  };

  _aggregateErrors = (verificationErrs, predicateFn) => {
    const errorsByMsg = Object.entries(verificationErrs).reduce(
      (prev, [key, errors]) => {
        return Object.entries(errors)
          .filter(([_, err]) => err && predicateFn(err))
          .reduce((acc, [_, err]) => {
            const keys = acc[err.msg] || [];
            return {
              ...acc,
              [err.msg]: [...keys, key]
            };
          }, prev);
      },
      {}
    );

    return Object.entries(errorsByMsg).map(([msg, keys]) => {
      return keys.length > 0 ? `[${keys.join(',')}]: ${msg}` : `${msg}`;
    });
  };

  _createErrorsPanel = () => {
    const { serverErrMsg, verificationErrs } = this.state;

    const msgGroups = {
      error: [
        ...this._aggregateErrors(verificationErrs, e => !e.isWarning),
        serverErrMsg
      ].filter(Boolean),
      warning: this._aggregateErrors(verificationErrs, e => e.isWarning).filter(
        Boolean
      )
    };

    return (
      <div>
        {Object.entries(msgGroups)
          .filter(([k, msgs]) => !_.isEmpty(msgs))
          .map(([k, msgs]) => (
            <Message
              key={k}
              error={k === 'error'}
              warning={k === 'warning'}
              list={msgs}
            />
          ))}
      </div>
    );
  };

  _createSummaryPanel = () => {
    const { trades, rawTrades } = this.state;

    const tradeSummary = _.map(
      trades.reduce((acc, t) => {
        const value = acc[t.side];
        acc[t.side] =
          value === undefined
            ? {
                count: 1,
                qtyUsd: t.qtyUsd || 0
              }
            : {
                count: value.count + 1,
                qtyUsd: value.qtyUsd + (t.qtyUsd || 0)
              };
        return acc;
      }, {}),
      (v, k) => {
        const sideStyle = ['BUY', 'COVR'].includes(k) ? 'long' : 'short';
        return (
          <span key={k} className={sideStyle}>{`${k}(${v.count}): ${numeral(
            v.qtyUsd
          ).format('$0,0')};  `}</span>
        );
      }
    );

    const parsedSummary = [
      `Totally ${_.size(trades)} of ${_.size(rawTrades)} trades are parsed.`,
      _.orderBy(tradeSummary, ['key'], ['asc'])
    ];

    return (
      !_.isEmpty(parsedSummary) && (
        <Message info>
          <div style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
            {parsedSummary.map((s, i) => (
              <div key={i}>{s}</div>
            ))}
          </div>
        </Message>
      )
    );
  };

  render() {
    const { isLoading } = this.state;
    const { user = {} } = this.props.settings;

    return (
      <Modal
        width={1450}
        maskClosable={false}
        title={`New Batch ${roleTradeLabel(user)}`}
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          this._createSubmitBtn(this._debouncedSubmit),
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        <Spin tip="Loading..." spinning={isLoading}>
          {this._createOperationBar()}
          {this._createTradesGrid()}
          {this._createSummaryPanel()}
          {this._createErrorsPanel()}
        </Spin>
      </Modal>
    );
  }
}

export default QuantTradesDialog;
