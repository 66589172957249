import fetch from 'isomorphic-fetch';
import config from 'common/config';
import apiClient from '../../../common/api/apiClient';

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new Error(`HTTP Error ${response.statusText}`);
    error.status = response.statusText;
    error.response = response;
    console.log(error);
    throw error;
  }
};

const showFile = (blob, format) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([blob], { type: `application/${format}` });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement('a');
  link.href = data;
  link.download = `report.${format}`;
  link.click();

  setTimeout(() => window.URL.revokeObjectURL(data), 100);
};

const getPortfolio = ({ date, fundCode, bookCode }) => {
  const qs = new URLSearchParams({ date, fundCode, bookCode });
  const url = `${config.api.PXY_URL}/portfolio/getPortfolio?${qs}`;

  return apiClient.get(url);
};

const downloadReport = ({ reportGroup, report, filter, format }) => {
  return fetch(config.api.REST_URL + '/report/download', {
    method: 'post',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      reportGroup,
      report,
      filter,
      format
    })
  })
    .then(checkStatus)
    .then(r => r.blob())
    .then(b => showFile(b, format));
};

const saveAnalyticTradeDataList = params => {
  const url = `${config.api.PXY_URL}/portfolio/saveAnalyticTradeDataList`;
  return apiClient.post(url, params);
};

const getAnalyticTradeDataList = params => {
  const url = `${config.api.PXY_URL}/portfolio/getAnalyticTradeDataList`;
  return apiClient.post(url, params);
};

const saveTagDataList = params => {
  const url = `${config.api.PXY_URL}/portfolio/saveTagDataList`;
  return apiClient.post(url, params);
};

const loadTagDataList = params => {
  const url = `${config.api.PXY_URL}/portfolio/loadTagDataList`;
  return apiClient.post(url, params);
};

const saveTrackPriceList = params => {
  const url = `${config.api.PXY_URL}/portfolio/saveTrackPriceList`;
  return apiClient.post(url, params);
};

const savePairTrackPriceList = params => {
  const url = `${config.api.PXY_URL}/portfolio/savePairTrackPriceList`;
  return apiClient.post(url, params);
};

const loadTrackPriceList = params => {
  const url = `${config.api.PXY_URL}/portfolio/loadTrackPriceList`;
  return apiClient.post(url, params);
};

const loadPairTrackPriceList = params => {
  const url = `${config.api.PXY_URL}/portfolio/loadPairTrackPriceList`;
  return apiClient.post(url, params);
};

const loadDefaultTrackPriceConfig = params => {
  const url = `${config.api.PXY_URL}/portfolio/loadDefaultTrackPriceConfig`;
  return apiClient.post(url, params);
};

const reviewOrder = params => {
  const url = `${config.api.PXY_URL}/research/reviewOrder`;
  return apiClient.post(url, params);
};

const updateOrderReason = params => {
  const url = `${config.api.RESEARCH_URL}/orderReview/updateOrderReason`;
  return apiClient.post(url, params);
};

const getSecurities = params => {
  const url = `${config.api.RESEARCH_URL}/orderReview/getSecurities`;
  return apiClient.post(url, params);
};

const getIndustrySentimentScoreResult = params => {
  const url = `${config.api.RESEARCH_URL}/industrySentimentScore/getResultList`;
  return apiClient.post(url, params);
};

const getIndustrySentimentIndustryList = () => {
  const url = `${config.api.RESEARCH_URL}/industrySentimentScore/getIndustryList`;
  return apiClient.post(url);
};

const getIndustryByTicker = params => {
  const url = `${config.api.RESEARCH_URL}/industrySentimentScore/getIndustryByTicker`;
  return apiClient.post(url, params);
};

const loadFxTradeReport = params => {
  const url = `${config.api.PXY_URL}/portfolio/loadFxTradeReport`;
  return apiClient.post(url, params);
};

const getFxTradeData = params => {
  const url = `${config.api.PXY_URL}/portfolio/getFxTradeData`;
  return apiClient.post(url, params);
};

const addNewsReadRecord = records => {
  const url = `${config.api.PXY_URL}/portfolio/addNewsReadRecord`;
  return apiClient.post(url, {
    data: records
  });
};

const getGptConferenceInfo = id => {
  const url = `${config.api.REST_URL}/portfolio/getGptConferenceInfo`;
  return apiClient.post(url, {
    id
  });
};

const client = {
  getPortfolio,
  downloadReport,
  saveAnalyticTradeDataList,
  getAnalyticTradeDataList,
  saveTagDataList,
  loadTagDataList,
  saveTrackPriceList,
  savePairTrackPriceList,
  loadTrackPriceList,
  loadPairTrackPriceList,
  loadDefaultTrackPriceConfig,
  reviewOrder,
  updateOrderReason,
  getSecurities,
  getIndustrySentimentScoreResult,
  getIndustrySentimentIndustryList,
  getIndustryByTicker,
  loadFxTradeReport,
  addNewsReadRecord,
  getGptConferenceInfo,
  getFxTradeData,
};

export default client;
