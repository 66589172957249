import React, { PureComponent } from 'react';
import { Modal, Button, Menu, Col, Row, InputNumber } from 'antd';
import client from '../api/client';
import _ from 'lodash';
import { update } from 'idb-keyval';

const { SubMenu } = Menu;
class UserSettingDialog extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      submitStatus: 'READY',
      paramsMap: { 
        TRADE: {
          'ALGO': {
            'INLINE Vol %': {
              pId: 3,
              cId: 2,
              dataType: 'PERCENT',
              defaultValue: 15,
              min: 1,
              precision:0,
              max: 30,
              formatter : (value) => `${value}%`,
              parser: (value) => { return value?value.replace('%', '') : null }
            }
          }
        }
      },
      data: [],
      selectedMenu: 'TRADE_ALGO',
      defaultSelected: ['TRADE_ALGO'],
      defaultOpenKeys: ['TRADE']
    };
  }

  _onSubmit = () => {
    const { data } = this.state;
    const { resetUserSettingDialog } = this.props;
    if(!data) return;
    const updateData = data.filter(r => r.dirty);
    if(!updateData) {
      console.log("no setting update")
      resetUserSettingDialog();
      return;
    }
    client.saveUserSettings(updateData).then(
      resp => {
        this.setState({
          data: []
        })
        resetUserSettingDialog();
      }
    ).catch(err => console.log(err));
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus } = this.state;
    return {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];
  };

  _onMenuSelect = (info) => {
    const selectedMenu = info.key;;
    this.setState({
      selectedMenu
    })
  }

  _createLeftPannel = () => {
    const { paramsMap } = this.state;
    return (
      <Menu
        onClick={this._onMenuSelect}
        defaultSelectedKeys={this.state.defaultSelected}
        defaultOpenKeys={this.state.defaultOpenKeys}
        style={{
          width: 200,
          height: 360
        }}
        mode="inline"
    >
      {Object.keys(paramsMap).map((r, index) => {
        if(_.isEmpty(paramsMap[r])) {
          return (<Menu.Item key={r}>
             <span>r</span>
          </Menu.Item>)
        } else {
          return (
            <SubMenu key={r} title={r}>
              {Object.keys(paramsMap[r]).map((e) => {
                return <Menu.Item key={`${r}_${e}`}>
                <span>{e}</span>
               </Menu.Item>
              })}
            </SubMenu>
          )
        }
      })}
      </Menu>
    )
  }

  onValueChange = (value, info) => {
    const { data } = this.state;
    const updateData = _.isEmpty(data)?[{...info, value, dirty: true}]:data.map(e => {
      if(e.pId === info.pId && e.cId === info.cId) {
        return {
          ...e,
          value,
          dirty: true,
        }
      }
      return { ...e };
    });
    if(_.isEmpty(updateData.filter(e => e.pId === info.pId && e.cId === info.cId))) {
      updateData.push({
        ...info,
        value,
        dirty: true,
      });
    }

    this.setState({
      data: updateData
    })
  }

  findCurrentItem = (r) => {
    const { defaultValue, pId, cId } = r;
    const { data } = this.state;
    const { customSettings } =  this.props.settings;
    if(!_.isEmpty(data)) {
      const item = _.first(data.filter(e => e.pId === pId && e.cId === cId))
      if(item) {
        return item;
      }
    }
    if(!_.isEmpty(customSettings)) {
      const item = _.first(customSettings.filter(e => e.pId === pId && e.cId === cId))
      if(item) {
        return item;
      }
    }
    return {value: defaultValue, pId, cId};
  }

  _createRightPannel = () => {
    const { selectedMenu, paramsMap } = this.state;
    if(!selectedMenu) return;
    const params = _.get(paramsMap, selectedMenu.replace('_', '.'));
    const comps = [];
    Object.keys(params).forEach(r => {
      comps.push(<Col key={`${r}_LABEL`} span={4} style={{margin: '3px'}}>{r}</Col>)
      const { dataType } = params[r];
      const currentItem = this.findCurrentItem(params[r]);
      if('PERCENT' === dataType) {
        comps.push(<Col key={`${r}_VAL`} span={15}><InputNumber style={{width: '300px'}} {...params[r]} value={currentItem.value} onChange={(v) => {this.onValueChange(v, currentItem)}} /></Col>)
      }
    })
    return (
      selectedMenu && 
      <Row gutter={24}>
        {comps}
      </Row>
    )
  }

  render() {
    const { resetUserSettingDialog } = this.props;
    const { userSettingModal } = this.props.settings;

    return (
      <Modal
        width={900}
        maskClosable={false}
        title="Settings"
        visible={userSettingModal.isOpened}
        onCancel={resetUserSettingDialog}
        footer={[
          this._createSubmitBtn(this._onSubmit),
          <Button key="close" type="primary" onClick={resetUserSettingDialog}>
            Close
          </Button>
        ]}
      >
       <Row gutter={24}>
        <Col span={6}>{this._createLeftPannel()}</Col>
        <Col span={18}>{this._createRightPannel()} </Col>
       </Row>
      </Modal>
    );
  }
}

export default UserSettingDialog;
