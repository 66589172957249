import { createReducer } from 'common/utils/reducerUtils';
import {
  LOAD_SETTINGS_SUCCESS,
  LOAD_SETTINGS_FAILURE,
  HELP_DIALOG_OPEN,
  HELP_DIALOG_RESET,
  USER_SETTING_DIALOG_OPEN,
  USER_SETTING_DIALOG_RESET,
  OPEN_USER_SETTINGS_SUCCESS,
  OPEN_USER_SETTINGS_FAILURE,
} from './settingConstants';
import { LOGOUT_SUCCESS } from '../auth/authConstants';
import {
  positionGridColumnMap,
  riskBarColumnMap
} from '../portfolio/components/GridColumnMap';
import { riskGridColumnMap } from '../risk/components/GridColumnMap';
import { tradeGridColumnMap } from '../order/components/GridColumnMap';
import { orderGridColumnMap } from '../oms/components/order/GridColumnMap';
import { routeGridColumnMap } from '../oms/components/route/GridColumnMap';
import { allocationGridColumnMap } from '../oms/components/allocation/GridColumnMap';
import EntityMap from 'entities/EntityMap';

const initialState = {
  funds: [],
  isLoaded: false,
  posGridColumns: [],
  tradeGridColumns: [],
  orderGridColumns: [],
  routeGridColumns: [],
  allocationGridColumns: [],
  riskBarColumns: [],
  riskGridColumns: [],
  fieldsByCategory: {},
  tradeLabel: 'Idea',
  routes: [],
  customSettings: [],

  helpModal: {
    isOpened: false
  },
  userSettingModal: {
    isOpened: false
  },
};

const _extractColumns = (fields, filterFn, columnDefs) => {
  return fields
    .filter(filterFn)
    .map(f => ({
      ...EntityMap.get(columnDefs, f.field)
    }))
    .filter(f => f.field);
};

const _extractColumnSettings = fields => {
  const posGridColumns = _extractColumns(
    fields,
    f => f.page === 'Portfolio' && f.part === 'Position',
    positionGridColumnMap
  );

  const riskBarColumns = _extractColumns(
    fields,
    f => f.page === 'Portfolio' && f.part === 'RiskBar',
    riskBarColumnMap
  );

  const tradeGridColumns = _extractColumns(
    fields,
    f => f.page === 'Trades' && f.part === 'OrderGrid',
    tradeGridColumnMap
  );

  const riskGridColumns = _extractColumns(
    fields,
    f => f.page === 'Risk' && f.part === 'RiskGrid',
    riskGridColumnMap
  );

  const categories = new Set(fields.map(f => f.category));
  const fieldsByCategory = [...categories].reduce((acc, c) => {
    acc[c] = EntityMap.fromArray(fields.filter(f => f.category === c), 'field');
    return acc;
  }, {});

  return {
    posGridColumns,
    tradeGridColumns,
    orderGridColumns: EntityMap.map(orderGridColumnMap),
    routeGridColumns: EntityMap.map(routeGridColumnMap),
    allocationGridColumns: EntityMap.map(allocationGridColumnMap),
    riskBarColumns,
    riskGridColumns,
    fieldsByCategory
  };
};

export function loadSettingsSuccess(state, payload) {
  const {
    funds,
    user,
    preference,
    fields,
    routes,
    fundBooks,
    tagFundBooks
  } = payload;
  const columnSettigns = _extractColumnSettings(fields);

  return {
    ...state,
    funds,
    user,
    isLoaded: true,
    tradeLabel: user.firm === 'PINPOINT' ? 'Trade' : 'Idea',
    preference,
    routes,
    fundBooks,
    tagFundBooks,

    ...columnSettigns
  };
}

export function loadSettingsFailure(state, payload) {
  return {
    ...initialState,
    isLoaded: true
  };
}

export function logoutSuccess() {
  return {
    ...initialState
  };
}

export function requestLoadSettings(state) {
  return initialState;
}

export function openHelpDialog(state) {
  return {
    ...state,
    helpModal: {
      ...state.helpModal,
      isOpened: true
    }
  };
}

export function resetHelpDialog(state) {
  return {
    ...state,
    helpModal: initialState.helpModal
  };
}

export function opUserSettingDialog(state) {
  return {
    ...state,
    userSettingModal: {
      ...state.userSettingModal,
      isOpened: true
    }
  };
}

export function resetUserSettingDialog(state) {
  return {
    ...state,
    userSettingModal: initialState.userSettingModal
  };
}

export function openUserSettingDialogSuccess(state, payload) {
  return {
    ...state,
    customSettings: payload
  };
}

export function openUserSettingDialogFailed(state) {
  return {
    ...state,
  };
}

export default createReducer(initialState, {
  [LOAD_SETTINGS_SUCCESS]: loadSettingsSuccess,
  [LOAD_SETTINGS_FAILURE]: loadSettingsFailure,
  [LOGOUT_SUCCESS]: logoutSuccess,
  [HELP_DIALOG_OPEN]: openHelpDialog,
  [HELP_DIALOG_RESET]: resetHelpDialog,
  [USER_SETTING_DIALOG_OPEN]: opUserSettingDialog,
  [USER_SETTING_DIALOG_RESET]: resetUserSettingDialog,
  [OPEN_USER_SETTINGS_SUCCESS]: openUserSettingDialogSuccess,
  [OPEN_USER_SETTINGS_FAILURE]: openUserSettingDialogFailed
});
