import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import agGridUtils from '../../../../common/ui/agGridUtils';
import { onShoreTradeSummmaryColumns } from './GridColumn';
import { Button, DatePicker, Spin, Select } from 'antd';
import client from '../../api/client';
import moment from 'moment';
import _ from 'lodash';

const Option = Select.Option;
const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};
class TradeSummaryReportGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agGridSettings: agGridUtils.createSettings({
        defaultColDef: {
          enableCellChangeFlash: true,
          minWidth: 25,
          sortable: true,
          filter: true,
          resizable: true
        },
        columnDefs: onShoreTradeSummmaryColumns,
        rowSelection: 'single',
        floatingFilter: false,
        groupIncludeTotalFooter: false
      }),
      autoGroupColumnDef: {
        cellClass: 'keyword',
        width: 80
        // field: 'pairName',
        // headerName: 'Name',
      },
      fundList: ['DCL', 'CVF', 'ZJNF', 'SLHL'],
      loadingStatus: false,
      data: [],
      queryParams: {
        fund: 'DCL',
        startDate: moment()
          .subtract(30, 'days')
          .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      }
    };
  }

  componentDidMount() {
    this._loadData();
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  };

  dateOnChange = (field, dateString) => {
    const { queryParams } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        [field]: dateString
      }
    });
  };

  _loadData = () => {
    const { queryParams } = this.state;
    this.setState({
      loadingStatus: true
    });
    client
      .queryTradeChargeSummary(queryParams)
      .then(resp => {
        this.setState({
          data: resp.data ? resp.data : [],
          loadingStatus: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loadingStatus: false
        });
      });
  };

  _createQueryHeader = () => {
    const { startDate, endDate, fund } = this.state.queryParams;
    return (
      <div style={{ textAlign: 'right' }}>
        <Select
          style={{ marginRight: '5px', width: '80px' }}
          value={fund}
          size="small"
          allowClear={false}
          onChange={value => {
            this.dateOnChange('fund', value);
          }}
        >
          {_createUIOptions(this.state.fundList)}
        </Select>
        <DatePicker
          style={{ marginRight: '5px', width: '120px' }}
          allowClear={false}
          format={'YYYY-MM-DD'}
          value={moment(startDate, 'YYYY-MM-DD')}
          size="small"
          onChange={(date, str) => {
            this.dateOnChange('startDate', str);
          }}
        />
        <DatePicker
          style={{ marginRight: '5px', width: '120px' }}
          allowClear={false}
          format={'YYYY-MM-DD'}
          value={moment(endDate, 'YYYY-MM-DD')}
          size="small"
          onChange={(date, str) => {
            this.dateOnChange('endDate', str);
          }}
        />
        <Button type="primary" size="small" onClick={this._loadData}>
          Reload
        </Button>
      </div>
    );
  };

  _createGrid = () => {
    const { className = 'grid-wrapper' } = this.props;
    const { agGridSettings, data, loadingStatus } = this.state;
    const screenHeight = window.screen.height;
    const gridDivHeight = `${((screenHeight - 100) / screenHeight) * 100}%`;
    return (
      <>
        {loadingStatus ? (
          <div
            style={{ height: '100%', textAlign: 'center', marginTop: '50px' }}
          >
            <Spin />
          </div>
        ) : (
          <div
            className={`ag-theme-balham-dark ${className}`}
            style={{
              height: gridDivHeight
            }}
          >
            <AgGridReact
              // properties
              rowData={data}
              {...agGridSettings}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              groupDefaultExpanded={0}
              animateRows={false}
              deltaRowDataMode={false}
              getContextMenuItems={this._getContextMenuItems}
              // events
              onGridReady={this.onGridReady}
            />
          </div>
        )}
      </>
    );
  };

  _getContextMenuItems = params => {
    return [];
  };

  render() {
    return (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {this._createQueryHeader()}
        {this._createGrid()}
      </div>
    );
  }
}

export default TradeSummaryReportGrid;
